import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, Paper, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowForward, CancelOutlined, CheckCircleOutlineOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router';

import { TitleContainer, ContentContainer, Cards, ActionsBox, DialogWrap } from 'src/components';
import { InsertLinkCodeDialog, AcceptRequestDialog, DeclineRequestDialog } from 'src/scenes/CompanySettings/scenes';
import { monitorApi } from 'src/services';
import { LinkPlus } from 'src/utils/mdIcons';


const useStyles = makeStyles(theme => ({
  addButton: {
    color: theme.palette.common.white,
  },
  paper: {
    padding: theme.spacing(4),
    background: 'none',
    border: `1px solid ${theme.palette.common.gray400}`,
  },
}));

const CompanyLinkedRequestContainer = () => {

  const history = useHistory();

  const [ linkedCompanies, setLinkedCompanies ] = useState([]);
  const [ linkRequests, setLinkRequests ] = useState([]);
  const [ dialog, setDialog ] = useState({ isOpen: false, type: '' });
  const classes = useStyles();

  const closeDialog = () => setDialog({ isOpen: false, type: '' });
  const openDialog = (type, data = {}) => setDialog({ isOpen: true, type, data });

  const getDialogType = () => {
    switch (dialog.type) {
      case 'insert-link-code':
        return <InsertLinkCodeDialog actions={{ closeDialog, sendLinkCode }} />;
      case 'accept-request':
        return <AcceptRequestDialog actions={{ closeDialog, acceptRequest: () => acceptRequest(dialog.data) }} />;
      case 'decline-request':
        return <DeclineRequestDialog actions={{ closeDialog, deleteRequest: () => deleteRequest(dialog.data) }} />;
      default:
        break;
    }
  };

  const sendLinkCode = async linkCode => {
    const linkRequest = await monitorApi.insertLinkCode({ linkCode });
    setLinkRequests(prev => [ linkRequest, ...prev ]);
  };

  const acceptRequest = async request => {
    const { id: requestId } = request;
    const linkedCompany = await monitorApi.acceptRequest({ requestId });
    setLinkRequests(prev => prev.filter(lr => lr.id !== requestId));
    setLinkedCompanies(prev => [ linkedCompany, ...prev ]);
  };

  const deleteRequest = async request => {
    const { id: requestId } = request;
    await monitorApi.deleteRequest(requestId);
    setLinkRequests(prev => prev.filter(lr => lr.id !== requestId));
  };

  useEffect(() => {
    const fetch = async () => {
      const linkedCompanies = await monitorApi.getLinkedCompanies();
      setLinkedCompanies(linkedCompanies);
      const linkRequests = await monitorApi.getLinkRequests();
      setLinkRequests(linkRequests);
    };
    fetch();
  }, []);

  const goToAllowedCampaigns = companyId => history.push(`/web/company/linked-apps/${companyId}/allowed-campaigns`);

  const companyCardActions = itemData => ([
    {
      Icon: ArrowForward,
      tooltip: 'Ver permisos de campañas',
      onClick: () => goToAllowedCampaigns(itemData.id),
    },
  ]);

  const requestsCardActions = itemData => ([
    {
      Icon: CheckCircleOutlineOutlined,
      tooltip: 'Aceptar',
      onClick: () => openDialog('accept-request', itemData),
    },
    {
      Icon: CancelOutlined,
      tooltip: 'Rechazar',
      onClick: () => openDialog('decline-request', itemData),
    },
  ]);

  return (
    <Grid container>
      <TitleContainer breadcrumbs={[ 'company', { name: 'Perfil' } ]}>Vinculaciones con Apps</TitleContainer>
      <ContentContainer maxWidth="lg">
        <Box pb={1}>
          <Box mt={4}>
            <Box display="flex">
              <Typography variant="h5" color="textPrimary">Empresas de <strong>Monitor</strong> vinculadas</Typography>
              <Box flexGrow={1}></Box>
              <ActionsBox>
                <Button size="small" color="primary" className={classes.addButton} startIcon={<LinkPlus />}
                  variant="contained" onClick={() => openDialog('insert-link-code')}>
                  Ingresar código de vinculación
                </Button>
              </ActionsBox>
            </Box>
            <Paper className={classes.paper} elevation={0}>
              <Box mt={-1}>
                <Cards values={linkedCompanies} actions={companyCardActions} variant="grid"/>
              </Box>
            </Paper>
          </Box>
          <Box mt={8}>
            <Typography variant="h5" color="textPrimary">Solicitudes pendientes</Typography>
            <Paper elevation={0} className={classes.paper}>
              {linkRequests.length ?
                <Cards values={linkRequests} actions={requestsCardActions} variant="grid"/> :
                <Typography variant="body2">No tienes solicitudes pendientes</Typography>
              }
            </Paper>
          </Box>
        </Box>
      </ContentContainer>
      <DialogWrap maxWidth='sm' fullWidth onClose={closeDialog} aria-labelledby='form-dialog-title' open={dialog.isOpen}>
        {getDialogType()}
      </DialogWrap>
    </Grid>
  );
};


export { CompanyLinkedRequestContainer };