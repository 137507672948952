import * as api from 'src/services/api';


const baseUrl = 'to-pay';
const get = (arg1, arg2, arg3) => api.get(arg1, arg2, arg3, baseUrl);
const del = (arg1, arg2, arg3) => api.del(arg1, arg2, arg3, baseUrl);
const post = (arg1, arg2, arg3) => api.post(arg1, arg2, arg3, baseUrl);

const getCreditCard = async () => (await get('credit-card')).data;

const startCreditCardSubscription = async () => (await get('start')).data;

const finishCreditCardSubscription = async tbkToken => (await get('finish', { tbkToken })).data;

const removeCreditCard = async () => (await del('credit-card'));

const getBillingData = async () => (await get('billing-data')).data;

const addBillingData = async billingData => (await post('billing-data', billingData)).data;

const getSubscription = async () => (await get('subscription')).data;

const getCompanyPlans = async () => (await get('company-plans')).data;

const getCompanyCustomPlan = async () => (await get('company-custom-plan')).data;

const createSubscription = async subscriptionData => (await post('subscription', subscriptionData)).data;

const getPaymentPreview = async () => (await get('preview')).data;

const getEconomicActivities = async () => (await get('economic-activity')).data;

const getPaymentHistory = async () => (await get('payment-history')).data;

const downloadPayment = async paymentId => (await get('download-payment', { paymentId }));


export {
  getCreditCard,
  startCreditCardSubscription,
  finishCreditCardSubscription,
  removeCreditCard,
  getBillingData,
  addBillingData,
  getSubscription,
  getCompanyPlans,
  createSubscription,
  getPaymentPreview,
  getEconomicActivities,
  getCompanyCustomPlan,
  getPaymentHistory,
  downloadPayment,
};