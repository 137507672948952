import React from 'react';
import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import PropTypes from 'prop-types';


const UsersActivitySummary = ({ usersActivity, onlyOneCompany }) => {

  const activeCompanies = Object.keys(usersActivity.reduce((p, c) => ({ ...p, [c.companyName]: true }), {})).length;
  const activeProjects = Object.keys(usersActivity.reduce((p, c) => ({ ...p, [c.projectId]: true }), {})).length;
  const activeCampaigns = Object.keys(usersActivity.reduce((p, c) => ({ ...p, [c.campaignId]: true }), {})).length;
  const activeUsers = Object.keys(usersActivity.reduce((p, c) => ({ ...p, [`${c.companyName}-${c.userEmail}`]: true }), {})).length;

  const totalSyncs = usersActivity.reduce((p, c) => p + c.totalSyncs, 0);
  const totalPointsSyncs = usersActivity.reduce((p, c) => p + c.samplingUnitsSynced, 0);
  const totalRecordsSyncs = usersActivity.reduce((p, c) => p + c.recordSynced, 0);

  return (
    <>
      <Box mt={1} display='flex' justifyContent="space-between">
        <Box flex={ 1 } pr={ 1 }>
          <Alert severity="info" variant='filled'>
            {
              onlyOneCompany ? <>Proyectos activos: <strong>{activeProjects}</strong></>
              : <>Empresas activas: <strong>{activeCompanies}</strong></>
            }
          </Alert>
        </Box>
        <Box flex={ 1 } pr={ 1 }>
          <Alert severity="info" variant='filled'>Campañas activas: <strong>{activeCampaigns}</strong></Alert>
        </Box>
        <Box flex={ 1 }>
          <Alert severity="info" variant='filled'>Usuarios activos: <strong>{activeUsers}</strong></Alert>
        </Box>
      </Box>
      <Box mt={1} mb={ 1 } display='flex' justifyContent="space-between">
        <Box flex={ 1 }>
          <Alert severity="info" variant='filled'>Sincronizaciones totales: <strong>{totalSyncs}</strong></Alert>
        </Box>
        <Box flex={ 1 } pl={ 1 } pr={ 1 }>
          <Alert severity="info" variant='filled'>Unidades sincronizadas: <strong>{totalPointsSyncs}</strong></Alert>
        </Box>
        <Box flex={ 1 }>
          <Alert severity="info" variant='filled'>Registros sincronizados: <strong>{totalRecordsSyncs}</strong></Alert>
        </Box>
      </Box>
    </>
  );
};

UsersActivitySummary.propTypes = {
  usersActivity: PropTypes.array,
  onlyOneCompany: PropTypes.bool,
};


export {
  UsersActivitySummary,
};