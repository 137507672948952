import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import urlJoin from 'url-join';
import PropTypes from 'prop-types';

import { AccessControl } from 'src/components';
import { ProjectsContainer, PlanningCampaignContainer, ProfileContainer, AlbumContainer, CatalogsInfoContainer,
  CompanyUsersContainer, CompanySettingsContainer, CompaniesContainer, FaqContainer, DownloadRedirection,
  MobileNotYetContainer, CompanyEnvironmentContainer, CampaignsContainer, UsageMetricsContainer, UnpaidContainer } from 'src/scenes';
import checkers from 'src/utils/checkers';
import CampaignContainer from 'src/scenes/Campaign/CampaignContainer';


const routes = [
  {
    id: 'projects',
    path: 'projects',
    Component: ProjectsContainer,
  },
  {
    id: 'profile',
    path: 'profile',
    Component: ProfileContainer,
    noEmailVerification: true,
    isSubscriptionRequired: false,
  },
  {
    id: 'companies',
    path: 'companies',
    action: 'companies-page:visit',
    Component: CompaniesContainer,
  },
  {
    id: 'usage-metrics',
    path: 'usage-metrics',
    action: 'usage-metrics-page:visit',
    Component: UsageMetricsContainer,
  },
  {
    id: 'album',
    path: 'album',
    Component: AlbumContainer,
  },
  {
    id: 'faq',
    path: 'faq',
    Component: FaqContainer,
    noEmailVerification: true,
  },
  {
    id: 'company-users',
    path: 'company/users',
    action: 'company-page:visit',
    Component: CompanyUsersContainer,
  },
  {
    id: 'company-environment',
    path: 'company/environment',
    Component: CompanyEnvironmentContainer,
  },
  {
    id: 'company',
    path: 'company',
    action: 'company-page:visit',
    Component: CompanySettingsContainer,
    hasNestedRoutes: true,
    isSubscriptionRequired: false,
  },
  {
    id: 'campaigns-by-project',
    path: 'projects/:projectId/campaigns',
    action: 'campaigns-page:visit',
    Component: CampaignsContainer,
  },
  // NOTE: esta ruta reemplaza a la vista de campañas, trae opciones del proyecto: campañas - planes de monitoreo - compromisos - analíticas
  // {
  //   id: 'project',
  //   path: 'projects/:projectId',
  //   // TODO: crear permiso para entrar al proyecto (que es distinto de ver campañas ahora que existen planes y compromiso)
  //   action: 'campaigns-page:visit',
  //   Component: ProjectContainer,
  //   hasNestedRoutes: true,
  // },
  // TODO: esta ruta es parte de las rutas de una campaña, debería estar el componente y todo dentro de Campaign
  {
    id: 'campaign-planning',
    path: 'campaigns/:campaignHash/planning',
    Component: PlanningCampaignContainer,
  },
  {
    id: 'campaign',
    path: 'campaigns/:campaignHash',
    action: 'campaign-page:visit',
    Component: CampaignContainer,
    hasNestedRoutes: true,
  },
  {
    id: 'download-redirect',
    path: 'download-file',
    Component: DownloadRedirection,
  },
  {
    id: 'catalogs',
    path: 'catalogs',
    Component: CatalogsInfoContainer,
  },
];

const WebRoutes = ({ company }) => {

  const { nviroPayCache, role } = company;

  const hasSub = checkers.hasSub(nviroPayCache?.subscriptionStatus);
  const isUnpaidSub = checkers.isUnpaidSub(nviroPayCache?.subscriptionStatus);
  const isAdmin = checkers.isAdmin(role);

  const { path: basePath } = useRouteMatch();
  const homePath = urlJoin(basePath, isUnpaidSub ? 'unpaid' : (!hasSub && isAdmin) ? 'company/subscription' : 'projects');
  const emptyPath = urlJoin(basePath, 'unpaid');
  const profilePath = urlJoin(basePath, 'profile');
  const mobilePath = urlJoin(basePath, 'mobile-not-yet');
  const subscriptionPath = urlJoin(basePath, 'company/subscription');
  const redirectToHomePath = () => <Redirect to={homePath} />;
  const redirectWithEmailCheck = ({ webContext }) =>
    webContext.currentUser?.emailVerified ? <Redirect to={homePath} /> : <Redirect to={profilePath} />;
  const redirectToMobilePath = () => <Redirect to={mobilePath} />;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  const redirectToSubscription = () => <Redirect to={subscriptionPath} />;

  const noAccessRedirect = checkEmailVerified =>
    isUnpaidSub ? redirectToSubscription
    : checkEmailVerified ? redirectWithEmailCheck
    : isMobile ? redirectToMobilePath
    : redirectToHomePath;

  return (
    <Switch>
      <Route exact path={basePath} component={isMobile ? redirectToMobilePath : redirectToHomePath} />
      <Route exact path={mobilePath} component={isMobile ? MobileNotYetContainer : redirectToHomePath} />
      <Route exact path={emptyPath} component={isAdmin ? redirectToSubscription : UnpaidContainer} />
      {routes.map(({ id, path, action, Component, hasNestedRoutes = false, noEmailVerification = false, isSubscriptionRequired = true }) =>
        <Route key={id} exact={!hasNestedRoutes} path={`${basePath}/${path}`} render={props =>
          <AccessControl
            action={action}
            checkEmailVerified={!noEmailVerification}
            noAccessRender={noAccessRedirect(!noEmailVerification)}
            isSubscriptionRequired={isSubscriptionRequired}
          >
            {isMobile ? <MobileNotYetContainer /> : <Component {...props} />}
          </AccessControl>
        } />,
      )}
    </Switch>
  );
};

WebRoutes.propTypes = {
  company: PropTypes.object,
};


export { WebRoutes };
