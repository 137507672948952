import React from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';

import { GraphWrapper } from 'src/components';
import { campaignStatisticsApi } from 'src/services';
import { getRandomColor, addOrReplaceOpacityInColor } from 'src/utils/util';
import { isPointQuadrat } from 'src/utils/checkers';


const RecordsByUser = props => {

  const apiCall = () => campaignStatisticsApi.getRecordsByUser(props.campaignHash);

  const campaignSubtypeMapper = {
    'plots': [
      { type: 'individual', title: 'Ind. de Flora' },
      { type: 'flora', title: 'Flora' },
      { type: 'forest', title: 'Forestales' },
    ],
    'point-quadrat': [
      { type: 'transect', title: 'Reg. Point-quadrat' },
    ],
    'point-intercept': [
      { type: 'presence', title: 'Reg. punto intercepto' },
    ],
    'stations': [
      { type: 'isolated', title: 'Reg. aislado' },
      { type: 'direct', title: 'Reg. directo' },
      { type: 'indirect', title: 'Reg. indirecto' },
      { type: 'transit', title: 'Reg. de tránsito' },
      { type: 'presence', title: 'Reg. de presencia' },
    ],
    'census': [
      { type: 'census', title: 'Reg. censo' },
    ],
  };

  const dataToGraphData = data => {

    const datasetsMetadata = campaignSubtypeMapper[props.mainMethodId].map(dm => ({ ...dm, color: getRandomColor() }));

    return {
      labels: data.map(d => d.email.replace(/@.*/, '')),
      datasets: datasetsMetadata.map(dm => (
        {
          label: dm.title,
          xLabel: '',
          data: data.map(d => d.data[dm.type]),
          backgroundColor: addOrReplaceOpacityInColor(dm.color, 0.7),
          borderColor: 'white',
          hoverBackgroundColor: addOrReplaceOpacityInColor(dm.color, 1),
        }
      )),
    };
  };

  return (
    <GraphWrapper
      title='Registros por colector'
      info='Muestra la cantidad de registros tomados por usuario'
      apiCall={apiCall}
      dataToGraphData={dataToGraphData}
      chartjsComponent={Bar}
      options={{
        plugins: {
          legend: { display: !isPointQuadrat(props.mainMethodId) },
          tooltip: {
            mode: 'index',
            axis: 'y',
            callbacks: {
              afterTitle: tooltipItem => {
                const totalRecords = tooltipItem.map(ti => parseFloat(ti.raw)).reduce((pv, cv) => pv + cv, 0);
                return `${totalRecords} registros`;
              },
            },
          },
        },
        scales: {
          x: { stacked: true },
          y: { stacked: true, ticks: { maxTicksLimit: 5 } },
        },
        ticks: {
          stepSize: 1,
        },
      } }
    />
  );
};

RecordsByUser.propTypes = {
  campaignHash: PropTypes.string.isRequired,
  mainMethodId: PropTypes.string.isRequired,
};


export { RecordsByUser };