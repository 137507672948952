import React, { memo } from 'react';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';

import { GraphWrapper } from 'src/components';
import { companiesApi } from 'src/services';


const maxDay = 31;

const dataToGraphData = data => ({
  labels: new Array(maxDay).fill().map((_, i) => i + 1),
  datasets: [ {
    label: 'Usuarios acumulados',
    data: data
      .map(({ day, totalAcc }) => ({ x: day, y: totalAcc })),
    yAxisID: 'yAxis',
    pointStyle: 'circle',
    pointRadius: 7,
    pointHoverRadius: 14,
  } ],
});

const legendOptions = { position: 'top' };

const graphOptions = {
  scales: {
    yAxis: {
      type: 'linear',
      position: 'left',
      title: { display: true, text: '# de usuarios acumulados' },
      min: 0,
    },
    x: {
      title: { display: true, text: 'Día del mes' },
    },
  },
};

const ActiveUsersAccumInMonthNoMemo = ({ year, month, personalCompanies, showAllCompanies }) => {
  const apiCall = () => companiesApi.getUsersSyncCounterByDayAcc({ year, month, personalCompanies, showAllCompanies });

  return (
    <GraphWrapper
      title={`Usuarios activos acumulados del mes`}
      info={ `Muestra la suma acumulada de los usuarios activos durante el mes que se está evaluando` }
      apiCall={ apiCall }
      dataToGraphData={ dataToGraphData }
      chartjsComponent={ Line }
      legend={ legendOptions }
      options={ graphOptions }
    />
  );
};

ActiveUsersAccumInMonthNoMemo.propTypes = {
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  personalCompanies: PropTypes.bool.isRequired,
  showAllCompanies: PropTypes.bool.isRequired,
};

const ActiveUsersAccumInMonth = memo(ActiveUsersAccumInMonthNoMemo);


export {
  ActiveUsersAccumInMonth,
};