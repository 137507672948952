import React, { useState, useEffect, useContext } from 'react';
import { Grid, Container, Box, Select, MenuItem, Button, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { TitleContainer, ContentContainer, AlertWrapper, TableLoader } from 'src/components';
import { UsersAndCampaignsByMonthGraph, ActiveUsersAccumInMonth } from 'src/scenes/UsageMetrics/components';
import { companiesApi } from 'src/services';
import { monthNames, prependZero } from 'src/utils/util';
import { WebContext } from 'src/scenes/web-context';
import { ActiveUsersSummaryAllTimeGraph, UsersActivityByCampaignList, UsersActivitySummary } from 'src/components/companyUsage';
import { MicrosoftExcel } from 'src/utils/mdIcons';
import { upperCaseOnlyFirstLetter } from 'src/utils/formatters';


const useStyles = makeStyles(theme => ({
  statisticCard: {
    height: 'calc(100vh - 130px)',
  },
  miniStatisticsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  statisticCardSmall: {
    height: theme.spacing(50),
    width: '49.5%',
  },
}));

const INITIAL_YEAR = 2023;
const currentDate = new Date();
const [ , currentYear, currentMonth ] = currentDate.toISOString().match(/^(\d{4})-(\d{2})-\d{2}/);
const UsageMetricsContainer = () => {

  const classes = useStyles();
  const { selectedCompany: { name: companyName } } = useContext(WebContext);

  const [ year, setYear ] = useState(currentYear);
  const [ month, setMonth ] = useState(currentMonth);
  const [ personalCompanies, setPersonalCompanies ] = useState(false);
  const [ showAllCompanies, setShowAllCompanies ] = useState(true);
  const [ usersActivity, setUsersActivity ] = useState([]);

  useEffect(() => {
    document.title = 'Métricas de uso - Nviro Capture';
  }, []); // eslint-disable-line

  const onChangeCompaniesOption = e => {
    const optionSelected = e.target.value;
    if (optionSelected === 0) { // Empresas
      setPersonalCompanies(false);
      setShowAllCompanies(true);
    } else if (optionSelected === 1) { // Usuarios Fan
      setPersonalCompanies(true);
      setShowAllCompanies(true);
    } else { // Empresa actual
      setPersonalCompanies(false);
      setShowAllCompanies(false);
    }
  };

  const companyOptionValue = !personalCompanies && showAllCompanies ? 0
    : personalCompanies && showAllCompanies ? 1
    : !personalCompanies && !showAllCompanies ? 2
    : -1;

  return (
    <Grid container >
      <TitleContainer maxWidth="xl">
        Métricas de uso
      </TitleContainer>
      <ContentContainer maxWidth="xl">
        <Container maxWidth="xl">
          <Box my={ 1 } flexDirection='row' display='flex' justifyContent='space-between'>
            <Box>
              <Select value={month} onChange={e => setMonth(e.target.value)}>
                {monthNames.map((name, idx) =>
                  <MenuItem key={idx} value={prependZero(idx + 1)}>{upperCaseOnlyFirstLetter(name)}</MenuItem>)
                }
              </Select>
              <Select value={ year } onChange={e => setYear(e.target.value)}>
                {
                  new Array(currentDate.getFullYear() - INITIAL_YEAR + 1).fill().map((_, i) => INITIAL_YEAR + i)
                    .map(year => <MenuItem key={year} value={`${year}`}>{year}</MenuItem>)
                }
              </Select>
              <Select value={companyOptionValue} onChange={ onChangeCompaniesOption }>
                <MenuItem value={ 0 }>Empresas</MenuItem>
                <MenuItem value={ 1 }>Usuarios Fan</MenuItem>
                <MenuItem value={ 2 }>{ companyName }</MenuItem>
              </Select>
            </Box>
            <Button
              size="small"
              href={ companiesApi.buildUsersActivityByCampaignExportUrl({ year, month, showAllCompanies }) }
              target="_blank"
              variant="outlined"
              color="primary"
              startIcon={<MicrosoftExcel />}
            >
              Exportar actividad de usuarios
            </Button>
          </Box>
          { usersActivity.length > 0 && <UsersActivitySummary usersActivity={ usersActivity } />}
          <Box mt={ 1 } mb={ 1 } className={ classes.miniStatisticsContainer }>
            <Card className={ classes.statisticCardSmall }>
              <UsersAndCampaignsByMonthGraph usersActivity={ usersActivity } />
            </Card>
            <Card className={ classes.statisticCardSmall }>
              <ActiveUsersAccumInMonth year={ year } month={ month } personalCompanies={ personalCompanies }
                showAllCompanies={ showAllCompanies }
              />
            </Card>
          </Box>
          <TableLoader
            apiCall={ companiesApi.getUsersActivityByCampaign }
            data={ { year, month, personalCompanies, showAllCompanies } }
            onSuccess={ activity => setUsersActivity(activity) }
          >
            <UsersActivityByCampaignList usersActivity={ usersActivity } personalCompanies={ personalCompanies }/>
          </TableLoader>
          <Box mt={ 1 } mb={ 1 }>
            <Card className={ classes.statisticCard }>
              <ActiveUsersSummaryAllTimeGraph showAllCompanies={ showAllCompanies } />
            </Card>
          </Box>
          <Box mt={ 2 }>
            <AlertWrapper severity="info">
              {
                personalCompanies ?
                  'Se toman en cuenta solo empresas personales' :
                  `Se ignoran las siguientes empresas: "Mi espacio", "Demo" y "Test"`
              }.
            </AlertWrapper>
          </Box>
          <Box mt={ 2 }>
            <AlertWrapper severity="info">
              Para el conteo de usuarios activos y su actividad se toma en cuenta lo siguiente:
              <ul>
                <li>El usuario debe haber sincronizado algún dato distinto de un conjunto tracks durante el mes</li>
                <li>No cuenta para el conteo la sincronización de estaciones de muestreo que no tengan datos</li>
              </ul>
            </AlertWrapper>
          </Box>
          <Box mt={ 2 } mb={ 3 }>
            <AlertWrapper severity="info">
              Son excluidos los siguientes correos del listado:
              <ul>
                {[ 'edward.moreno@nviro.cl', 'edward.moreno@csw.cl', 'edward.moreno.94@gmail.com', 'edward_moreno_94@hotmail.com',
                  'yhann.que@no.existe.cl', 'yhann.jara@faq.cl', 'yhann.jara@outlook.com', 'yhann.jara@nviro.cl', 'franco.pino@nviro.cl',
                  'sormeno@nviro.cl' ].map(mail => <li key={mail}>{mail}</li>)
                }
              </ul>
            </AlertWrapper>
          </Box>
        </Container>
      </ContentContainer>
    </Grid>
  );
};


export {
  UsageMetricsContainer,
};