import React, { useEffect, useState } from 'react';
import { Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';

import { SnackbarWrapper } from 'src/components';
import checkers from 'src/utils/checkers';
import { Link } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
  inWeb: {
    marginTop: theme.spacing(10),
    width: '85%',
  },
  inWebAlert: {
    width: '100%',
  },
}));

// eslint-disable-next-line
const CompanyAccountMessage = ({ userName, isAdmin, companyName, handleClose, isLastCallPeriod }) =>
  isLastCallPeriod ?
    <>
      Estimad@ {userName} la fecha límite para suscribirse a un plan ha expirado, la cuenta de la empresa {companyName} será suspendida
      si es que no se regulariza la situación antes del 8 de Noviembre de 2024 {isAdmin && <>, puede visualizar los planes disponibles en
      este <Link onClick={handleClose} to="/web/company/subscription/plans">enlace</Link>, si tienes cualquier duda escríbenos
      a contacto@nviro.cl.</>
      }
    </>
    :
    <>
      Estimad@ {userName} en nuestro constante proceso de mejora y optimización, hemos automatizado nuestra forma de pago, debido a esto,
      es necesario que antes del 31 de Octubre de 2024 la empresa {companyName} se suscriba a alguno de los planes disponibles en
      este <Link onClick={handleClose} to="/web/company/subscription/plans">enlace</Link>. Es muy importante que este proceso se realice
      a tiempo para evitar la suspensión de la cuenta, si tienes cualquier duda escríbenos a contacto@nviro.cl.
    </>;

// eslint-disable-next-line
const PersonalAccountMessage = ({ userName }) =>
  <>
    Estimado {userName}, como parte de las continuas mejoras de seguridad y funcionalidades de la plataforma de Nviro Capture
    le contamos que ahora es posible suscribirse con el fin de poner a su disposición el máximo potencial de la
    plataforma, puedes revisar <Link to="/web/profile/subscription">aquí</Link> los detalles.
  </>;

const NoSubscriptionAlert = ({ company, user }) => {

  const classes = useStyles();
  const [ snackbarOpen, setSnackbarOpen ] = useState(true);
  const isLastCallPeriod = checkers.isLastCallPeriod(company.nviroPayCache);
  const hasSub = checkers.hasSub(company.nviroPayCache);
  const isAdmin = checkers.isAdmin(company.role);
  const isAdminOrCoordinator = isAdmin || checkers.isCoordinator(company.role);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  useEffect(() => {
    setSnackbarOpen(true);
  }, [ company.name ]);

  const isPersonal = company.private;
  const userName = [ user.firstName, user.lastName ].join(' ');

  return (
    <>
      {!hasSub && isAdminOrCoordinator && !isPersonal && // TODO: quitar !isPersonal cuando exista la suscripción en personas
        (
          <SnackbarWrapper
            classes={{ root: classes.inWeb }}
            open={snackbarOpen}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            transitionDuration={1000}
            TransitionComponent={Fade}
          >
            <Alert classes={{ root: classes.inWebAlert }} onClose={handleClose} variant="filled"
              severity={isLastCallPeriod ? 'error' : 'warning'}
            >
              {isPersonal ?
                <PersonalAccountMessage userName={userName} />
                : <CompanyAccountMessage
                  handleClose={handleClose}
                  userName={userName}
                  isAdmin={isAdmin}
                  companyName={company.name}
                  isLastCallPeriod={isLastCallPeriod}
                />
              }
            </Alert>
          </SnackbarWrapper>
        )
      }
    </>
  );
};

NoSubscriptionAlert.propTypes = {
  company: PropTypes.object,
  user: PropTypes.object,
};


export { NoSubscriptionAlert };
