import React from 'react';
import PropTypes from 'prop-types';
import { DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';


const SubscriptionSuccessDialog = ({ actions }) =>
  <>
    <DialogTitle>Suscripción Exitosa</DialogTitle>
    <DialogContent>
      <DialogContentText>
        ¡Tu suscripción ha sido realizada con éxito!
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={actions.closeDialog} color="primary" autoFocus>
        Cerrar
      </Button>
    </DialogActions>
  </>;

SubscriptionSuccessDialog.propTypes = {
  actions: PropTypes.object,
};


export { SubscriptionSuccessDialog };