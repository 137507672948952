import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { useFetchStatus } from 'src/hooks';
import { TableSkeleton } from 'src/components/table/TableSkeleton';


const TableLoader = ({ apiCall, data, onSuccess, onError, children }) => {
  const { fetchStatus, setFetchStatus, fetchStatusEnum, errorMessage, setErrorMessage, hasError } = useFetchStatus();
  const { LOADING, SUCCESS, ERROR } = fetchStatusEnum;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetchStatus(LOADING);
        const resp = await apiCall(data);
        onSuccess?.(resp);
        setFetchStatus(SUCCESS);
      } catch (e) {
        onError?.(e);
        setFetchStatus(ERROR);
        setErrorMessage(e.serverMessage);
      }
    };
    fetchData();
  }, Object.keys(data).sort().map(k => String(data[k]))); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {
        fetchStatus === LOADING ? <TableSkeleton />
        : fetchStatus === SUCCESS ? children
        : null
      }
      {hasError && <Box mt={1}><Alert severity="error" variant='filled'>{errorMessage}</Alert></Box> }
    </>
  );
};

TableLoader.propTypes = {
  apiCall: PropTypes.func,
  data: PropTypes.object,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.object,
};


export { TableLoader };
