import React from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';


const useStyles = makeStyles(theme => ({
  root: ({ textColor }) => ({
    borderRadius: theme.shape.borderRadius,
    ...(textColor ? { color: textColor, borderColor: textColor } : {}),
  }),
  label: {
    height: ({ size }) => size === 'large' ? theme.spacing(3.5) : theme.spacing(2),
    fontSize: ({ size }) => size === 'large' ? '1rem' : '0.75rem',
  },
  // TODO: esta propiedad no hace nada???
  color: {
    backgroundColor: ({ color = 'gray600' }) => {
      const colorStr = color.split('.');
      return (
        colorStr.length === 1 ? theme.palette.common[colorStr[0]]
        : colorStr.length === 2 ? theme.palette[colorStr[0]][colorStr[1]]
        : colorStr.length === 3 ? theme.palette[colorStr[0]][colorStr[1]][colorStr[2]]
        : theme.palette.common.gray600
      );
    },
    // TODO: poner letra oscura cuando el color del background es claro (hue < 500)
    color: theme.palette.common.white,
  },
}));

// TODO: arreglar variant outlined para los chips cuando no se usan los colores del tema
const LabelChip = ({ color, textColor, className = '', size = 'small', ...props }) => {
  const classes = useStyles({ color, textColor, size });
  const isThemeColor = [ 'default', 'primary', 'secondary' ].includes(color);
  return (
    <Chip
      className={clsx(className, classes.root, classes.label, { [classes.color]: !isThemeColor })}
      {...(isThemeColor ? { color } : {})}
      {...props}
    />
  );
};

LabelChip.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  textColor: PropTypes.string,
  size: PropTypes.string,
};


export {
  LabelChip,
};