import React, { useEffect, useState } from 'react';
import { Grid, Box, Button, Typography, Paper, Link, Divider, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as Scroll from 'react-scroll';

import { TitleContainer, ContentContainer, DialogWrap, LabelChip, InfoTooltip } from 'src/components';
import { Payment, Receipt } from '@material-ui/icons';
import { payApi } from 'src/services';
import { useLocation, useHistory } from 'react-router';
import { ActiveUsersProgress, BillingDataAddDialog, RemoveCreditCardDialog }
  from 'src/scenes/CompanySettings/scenes/Subscription/components';
import { pricing } from 'src/scenes/Home/scenes/Pricing/pricingData';
import { CheckIcon, CloseIcon } from 'src/utils/mdIcons';
import { formatDate } from 'src/utils/util';
import { hasSub } from 'src/utils/checkers';
import { thousandsDotSeparator as tds, truncateWithEllipsis as twe } from 'src/utils/util';
import { upperCaseOnlyFirstLetter as ucofl } from 'src/utils/formatters';


const Element = Scroll.Element;
const scroller = Scroll.scroller;
const headerHight = 97;
const scrollConfig = { duration: 500, smooth: true, offset: -1 * headerHight };

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    background: 'none',
    border: `1px solid ${theme.palette.common.gray400}`,
  },
  creditCard: {
    width: 'fit-content',
  },
  removeCardBtn: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  billingPaper: {
    height: '100%',
    width: '100%',
    maxWidth: '480px',
  },
  billingTitle: {
    fontWeight: 500,
  },
  billingLinks: {
    borderLeft: `1px solid ${theme.palette.common.gray400}`,
    marginLeft: theme.spacing(2),
  },
  activeUsersProgress: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    background: 'none',
    border: `1px solid ${theme.palette.common.gray400}`,
  },

  feature: {
    fontSize: '1rem',
  },
  icon: {
    fontWeight: 'bold',
  },
  title: {
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  description: {
    color: theme.palette.common.gray600,
  },
  closeIcon: {
    color: theme.palette.error.main,
  },
  notIncluded: {
    height: '100%',
  },
  fontWeight500: {
    fontWeight: 500,
  },
}));

const CompanySubscriptionContainer = () => {

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [ creditCard, setCreditCard ] = useState();
  const [ billingData, setBillingData ] = useState();
  const [ subscription, setSubscription ] = useState();
  const [ plans, setPlans ] = useState([]);
  const [ paymentPreview, setPaymentPreview ] = useState({ amount: 0, extraAmount: 0, nextChargeDate: new Date() });
  const [ options, setOptions ] = useState({ economicActivities: [] });

  const hasCreditCard = Boolean(creditCard?.lastDigits);
  const hasBillingData = Boolean(billingData?.rut);

  const query = new URLSearchParams(location.search);
  const tbkToken = query.get('TBK_TOKEN');
  const hasTbkToken = Boolean(tbkToken);
  const isAborted = Boolean(query.get('TBK_ORDEN_COMPRA'));

  useEffect(() => {
    const fetch = async () => {
      const creditCard = await payApi.finishCreditCardSubscription(tbkToken);
      setCreditCard(creditCard);
    };

    if (hasTbkToken && !isAborted) {
      fetch();
    }
    history.push({ pathname: location.pathname });
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const creditCard = await payApi.getCreditCard();
      setCreditCard(creditCard);
    };
    if (!hasTbkToken) {
      fetch();
    }

    const fetchData = async () => {
      const [ billing, subscription, plans, customPlan, paymentPreview, economicActivities ] = await Promise.all([
        payApi.getBillingData(),
        payApi.getSubscription(),
        payApi.getCompanyPlans(),
        payApi.getCompanyCustomPlan(),
        payApi.getPaymentPreview(),
        payApi.getEconomicActivities(),
      ]);
      const hasAvailableCustomPlan = Boolean(customPlan);
      if (hasAvailableCustomPlan) {
        customPlan.name = 'Personalizado';
        customPlan.description = 'Para empresas con requerimientos específicos';
      }
      setPlans([ ...plans, ...(hasAvailableCustomPlan ? [ customPlan ] : []) ]);
      setSubscription(subscription);
      setBillingData(billing);
      setPaymentPreview(paymentPreview);
      const economicActivitiesOptions = economicActivities.map(({ id, code, name }) => ({ value: id, label: [ code, name ].join(' - ') }));
      setOptions(prevOptions => ({ ...prevOptions, economicActivities: economicActivitiesOptions }));
    };
    fetchData();
  // eslint-disable-next-line
  }, []);


  const handleCreditCardSubscription = async () => {
    const { token, redirectUrl } = await payApi.startCreditCardSubscription();
    goToTransbank(token, redirectUrl);
  };

  const goToTransbank = (token, url) => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = url;
    const hiddenFieldSample1 = document.createElement('input');
    hiddenFieldSample1.type = 'hidden';
    hiddenFieldSample1.name = 'TBK_TOKEN';
    hiddenFieldSample1.value = token;
    form.appendChild(hiddenFieldSample1);
    document.body.appendChild(form);
    form.submit();
  };

  const [ dialog, setDialog ] = useState({ isOpen: false, type: '' });

  const closeDialog = () => setDialog({ isOpen: false, type: '' });
  const openDialog = (type, data) => setDialog({ isOpen: true, type, data });
  const openBillingDataAddDialog = () => openDialog('edit-billing');
  const openCreditCardRemoveDialog = data => openDialog('remove-card', data);

  const getDialogType = () => {
    switch (dialog.type) {
      case 'edit-billing':
        return <BillingDataAddDialog actions={{ closeDialog, addBillingData }} options={options} prevBillingData={billingData} />;
      case 'remove-card':
        return <RemoveCreditCardDialog actions={{ closeDialog, removeCreditCard }} lastDigits={dialog.data} />;
      default:
        break;
    }
  };

  const addBillingData = async newBillingData => {
    const billing = await payApi.addBillingData({ billingDataId: billingData?.id, ...newBillingData });
    setBillingData(prev => ({ ...prev, ...billing }));
  };

  const removeCreditCard = async () => {
    await payApi.removeCreditCard();
    setCreditCard(undefined);
  };

  const goToPlans = () => history.push(`/web/company/subscription/plans`);

  const currentPlan = plans.find(plan => plan.id === subscription?.planId) || {};
  const currentPricing = pricing[subscription?.planId];

  const economicActivityLabel = hasBillingData ?
    [ billingData.economicActivityCode, ucofl(billingData.economicActivityName) ].join(' - ') : '';

  const planBaseAmount = Math.round(paymentPreview.amount);
  const extraUsersAmount = Math.round(paymentPreview.extraUsersAmountTotal);
  const gbsExtraAmount = Math.round(paymentPreview.extraGbsAmountTotal);
  const totalIvaAmount = Math.round((planBaseAmount + extraUsersAmount + gbsExtraAmount) * 0.19);
  const totalAmount = Math.round(planBaseAmount + extraUsersAmount + gbsExtraAmount + totalIvaAmount);

  return (
    <Grid container>
      <TitleContainer breadcrumbs={[ 'company', { name: 'Suscripción' } ]}>Suscripción</TitleContainer>
      <ContentContainer maxWidth="lg">
        <Box pb={1}>
          {hasSub(subscription) &&
            <Box py={4}>
              <Typography variant="h4" color="primary">Facturación</Typography>
              <Paper elevation={0} className={classes.paper}>
                <Box display="flex">
                  <Box width="50%">
                    <Paper className={classes.billingPaper}>
                      <Box p={2} display="flex" flexDirection="column" height="100%">
                        <Box display="flex" mb={0.5}>
                          <Typography variant="body2" className={classes.billingTitle}>Tu siguiente pago</Typography>
                          <InfoTooltip contents={
                            <Box display="flex" flexDirection="column">
                              <Typography variant="caption">Plan base: ${tds(planBaseAmount)}</Typography>
                              <Typography variant="caption">Usuarios extras: ${tds(extraUsersAmount)}</Typography>
                              <Typography variant="caption">GBs extras: ${tds(Math.floor(gbsExtraAmount))}</Typography>
                              <Typography variant="caption">Total IVA: ${tds(Math.floor(totalIvaAmount))}</Typography>
                            </Box>
                          } />
                        </Box>
                        <Box mb={1} display="flex" alignItems="baseline">
                          <Typography variant="h4">{tds(totalAmount)} CLP</Typography>
                          <Box ml={1}>
                            <Typography variant="body2" color="textSecondary">
                              Con cargo el {formatDate(new Date(paymentPreview.nextChargeDate))}
                            </Typography>
                          </Box>
                        </Box>
                        <Box mb={1} display="flex" flexDirection="column">
                          <Box alignSelf="flex-end">
                            <Typography variant="caption" color="textSecondary">
                              Usuarios activos en el periodo - <Link variant="caption" href='/web/company/usage'>Ver detalle</Link>
                            </Typography>
                          </Box>
                          <Paper elevation={0} className={classes.activeUsersProgress}>
                            <ActiveUsersProgress usersLimit={currentPlan?.usersLimit} activeUsers={paymentPreview.activeUsers} />
                          </Paper>
                        </Box>
                      </Box>
                    </Paper>
                  </Box>
                  <Box p={2} width="50%" className={classes.billingLinks} display="flex" flexDirection="column">
                    <Link variant="body2" onClick={() => scroller.scrollTo('current', scrollConfig)} >
                      Suscripción actual
                    </Link>
                    <Link variant="body2" onClick={() => scroller.scrollTo('billing', scrollConfig)}>
                      Datos de facturación
                    </Link>
                    <Link variant="body2" onClick={() => scroller.scrollTo('card', scrollConfig)}>
                      Medio de pago
                    </Link>
                    {/* <Link variant="body2">Historial de pagos</Link> */}
                  </Box>
                </Box>
              </Paper>
            </Box>
          }
          <Box py={4}>
            <Element name="current">
              <Box display="flex" alignItems="flex-end" pb={0.5}>
                <Box flexGrow={1}>
                  <Typography variant="h4" color="primary">Suscripción actual</Typography>
                </Box>
                <Box>
                  <Button color="primary" variant="outlined" size="small" onClick={goToPlans}>Comparar planes</Button>
                </Box>
                <Box ml={1}>
                  <Button color="primary" variant="contained" size="small" onClick={goToPlans}>Actualizar plan</Button>
                </Box>
              </Box>
            </Element>
            <Paper elevation={0} className={classes.paper}>
              {hasSub(subscription) && currentPlan ?
                <Grid container spacing={2}>
                  <Grid item>
                    <Paper>
                      <Box display="flex" p={4} flexDirection="column">
                        <Typography color="textPrimary" variant="h6" className={classes.title}>
                          Plan {currentPlan?.name}
                        </Typography>
                        <Typography color="textSecondary" variant="body2" className={classes.description}>
                          {currentPlan?.description}
                        </Typography>
                        <Box mt={1} display="flex" alignContent="center">
                          <Box mr={1}>
                            <LabelChip color="default" variant="outlined" label={`Modalidad ${subscription.billingFrequencyName}`} />
                          </Box>
                          <Box>
                            <LabelChip color="default" variant="outlined" label={`Termina el ${formatDate(subscription.endDate)}`} />
                          </Box>
                        </Box>
                        {currentPricing && <Box py={2}><Divider /></Box>}
                        {currentPricing?.features.data.map((feat, index) =>
                          <Box key={index} display="flex" py={1} width="100%" alignItems="center">
                            <Box pr={2} display="flex" alignItems="center">
                              <CheckIcon className={classes.icon} fontSize="small" color="primary" />
                            </Box>
                            <Box flexGrow={1}>
                              <Typography color="textPrimary" variant="body2">{feat}</Typography>
                            </Box>
                          </Box>,
                        )}
                      </Box>
                    </Paper>
                  </Grid>
                  {currentPricing?.notFeatures.data.length > 0 &&
                    <Grid item>
                      <Paper className={classes.notIncluded}>
                        <Box display="flex" p={4} flexDirection="column">
                          <Typography color="textPrimary" variant="h6">No incluye</Typography>
                          <br />
                          {currentPricing.notFeatures.data.map((feat, index) =>
                            <Box key={index} display="flex" py={1} width="100%" alignItems="center">
                              <Box pr={2} display="flex" alignItems="center">
                                <CloseIcon className={classes.closeIcon} fontSize="small" />
                              </Box>
                              <Box flexGrow={1}>
                                <Typography color="textPrimary" variant="body2">{feat}</Typography>
                              </Box>
                            </Box>,
                          )}
                        </Box>
                      </Paper>
                    </Grid>
                  }
                </Grid>
                : <Box display="flex" alignItems="center">
                  <Typography variant="body1">
                    No hay ninguna subscripción contratada, puedes revisar la que más te conviene <Link onClick={goToPlans}>aquí</Link>
                  </Typography>
                </Box>
              }
            </Paper>
          </Box>
          <Box py={4}>
            <Element name="billing">
              <Box display="flex" alignItems="flex-end" pb={0.5}>
                <Box flexGrow={1}>
                  <Typography variant="h4" color="primary">Datos de facturación</Typography>
                </Box>
                <Box>
                </Box>
                {hasBillingData &&
                  <Box ml={1}>
                    <Button color="primary" variant="contained" size="small" startIcon={<Receipt />} onClick={openBillingDataAddDialog}>
                      Editar datos de facturación
                    </Button>
                  </Box>
                }
              </Box>
            </Element>
            <Paper elevation={0} className={classes.paper}>
              {hasBillingData ?
                <Box display="flex" alignItems="center">
                  <Paper>
                    <Box p={4}>
                      <Box display="flex" flexDirection="column">
                        <Typography color="textPrimary" variant="body1">
                          <strong>{billingData.legalName}</strong>
                        </Typography>
                        <Typography className={classes.fontWeight500} color="textPrimary" variant="body1">
                          {billingData.rut}
                        </Typography>
                        <Typography className={classes.fontWeight500} color="textPrimary" variant="body2">
                          <Tooltip title={economicActivityLabel}>
                            <span>
                              {twe(economicActivityLabel, 64)}
                            </span>
                          </Tooltip>
                        </Typography>
                        <br />
                        {billingData.city &&
                          <Typography color="textPrimary" variant="body2">
                            {billingData.city}
                          </Typography>
                        }
                        <Typography color="textPrimary" variant="body2">
                          {billingData.commune}
                        </Typography>
                        <Typography color="textPrimary" variant="body2">
                          {billingData.address}
                        </Typography>
                        <br />
                        <Typography color="textPrimary" variant="body2">
                          {billingData.contact}
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Box>
                : <Box display="flex" alignItems="center">
                  <Typography variant="body1">No se ha agregado datos de facturación</Typography>
                  <Box flexGrow={1} />
                  <Button variant="outlined" onClick={openBillingDataAddDialog} color="primary" startIcon={<Receipt />}>
                    Agregar datos de facturación
                  </Button>
                </Box>
              }
            </Paper>
          </Box>
          <Box py={4}>
            <Element name="card">
              <Box display="flex" alignItems="flex-end" pb={0.5}>
                <Box flexGrow={1}>
                  <Typography variant="h4" color="primary">Medio de pago</Typography>
                </Box>
                <Box>
                </Box>
                {hasCreditCard &&
                  <Box ml={1}>
                    <Button
                      className={classes.removeCardBtn}
                      startIcon={<Payment />}
                      variant="contained"
                      size="small"
                      onClick={() => openCreditCardRemoveDialog(creditCard.lastDigits)}
                    >
                      Eliminar medio de pago
                    </Button>
                  </Box>
                }
              </Box>
            </Element>
            <Paper elevation={0} className={classes.paper}>
              {hasCreditCard ?
                <Box display="flex" alignItems="center">
                  <Box flexGrow={1}>
                    <Paper className={classes.creditCard}>
                      <Box display="flex" p={2}>
                        <Payment />
                        <Box ml={1}>
                          <Typography variant='body1'>
                            {creditCard.type} {creditCard.lastDigits}
                          </Typography>
                        </Box>
                      </Box>
                    </Paper>
                  </Box>
                </Box>
                :
                <Box display="flex" alignItems="center">
                  <Typography variant="body1">No se ha asociado un medio de pago</Typography>
                  <Box flexGrow={1} />
                  <Button variant="outlined" onClick={handleCreditCardSubscription} startIcon={<Payment />} color="primary">
                    Agregar medio de pago
                  </Button>
                </Box>
              }
            </Paper>
          </Box>
        </Box>
      </ContentContainer>
      <DialogWrap maxWidth='sm' fullWidth onClose={closeDialog} aria-labelledby='form-dialog-title' open={dialog.isOpen}>
        {getDialogType()}
      </DialogWrap>
    </Grid>
  );
};


export { CompanySubscriptionContainer };