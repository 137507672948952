import React from 'react';
import { Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Line, Doughnut } from 'react-chartjs-2';

import { IndicatorUI, ChartUI } from 'src/scenes/Home/components';
import theme from 'src/themes';


const species = {
  title: 'Especies totales',
  mainCount: 98,
  mainText: 'Especies encontradas',
  mainColor: theme.nviro.palette.flora.primary.main,
  secondaryCount: 173,
  secondaryText: 'registros',
};

const saturationCurve = {
  title: 'Curva de Saturación de Especies',
  chart: Line,
  data: {
    labels: [ '22/04', '23/04', '24/04', '25/04', '26/04', '27/04' ],
    datasets: [
      {
        data: [ 30, 90, 120, 130, 140, 145 ],
        label: 'Riqueza',
        backgroundColor: 'rgba(247, 147, 30, 0.7)',
        borderColor: 'rgba(247, 147, 30, 0.7)',
        hoverBackgroundColor: 'rgba(247, 147, 30, 1)',
        fill: false,
      },
    ],
  },
  options: {
    scales: {
      y: {
        ticks: {
          callback: value => Number.isInteger(value) ? value : undefined,
        },
      },
    },
  },
  legend: { display: false },
};

const generalPointsProgress = {
  title: 'Progreso en parcelas',
  chart: Doughnut,
  data: {
    labels: [
      'Pendientes',
      'Sincronizadas',
      'Revisadas',
    ],
    datasets: [
      {
        data: [ 90, 27, 3 ],
        backgroundColor: [
          'rgba(244, 67, 54, 0.7)',
          'rgba(81, 147, 235, 0.7)',
          'rgba(0, 200, 83, 0.7)',
        ],
        borderColor: 'white',
        hoverBackgroundColor: [ 'rgba(244, 67, 54, 1)', 'rgba(81, 147, 235, 1)', 'rgba(0, 200, 83, 1)' ],
      },
    ],
  },
  options: { maintainAspectRatio: false },
  legend: { position: 'top' },
};

const useStyles = makeStyles(theme => ({
  container: {
    overflowX: 'hidden',
    alignItems: 'center',
  },
  topContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  speciesContainer: {
    marginRight: theme.spacing(5),
    marginTop: theme.spacing(7),
    width: '250px',
    height: '140px',
    [theme.breakpoints.down('lg')]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(0),
    },
  },
  pointsProgressContainer: {
    width: '200px',
    [theme.breakpoints.down('lg')]: {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(0),
    },
  },
  saturationContainer: {
    width: '500px',
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('lg')]: {
      width: '98%',
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      width: '110%',
      marginLeft: theme.spacing(0),
      marginTop: theme.spacing(0),
      transform: 'scale(0.85)',
    },
  },
}));

const StatisticsCompositionUI = () => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" flexDirection="column" className={classes.container}>
      <Box className={classes.topContainer} display="flex">
        <Box className={classes.speciesContainer} alignItems="center">
          <Paper>
            <IndicatorUI {...species} />
          </Paper>
        </Box>
        <Box className={classes.pointsProgressContainer}>
          <Paper>
            <ChartUI {...generalPointsProgress} />
          </Paper>
        </Box>
      </Box>
      <Box className={classes.saturationContainer}>
        <Paper>
          <ChartUI {...saturationCurve} />
        </Paper>
      </Box>
    </Box>
  );
};


export {
  StatisticsCompositionUI,
};