import React, { memo, useMemo } from 'react';
import { Chart } from 'react-chartjs-2';
import PropTypes from 'prop-types';

import { GraphWrapper } from 'src/components';


const dataToGraphData = data => ({
  labels: data.map(d => d.company),
  datasets: [
    {
      type: 'bar',
      label: 'Usuarios activos',
      data: data.map(d => d.usersActivity),
      yAxisID: 'yAxis',
      order: 2,
    },
    {
      type: 'line',
      label: 'Campañas',
      data: data.map(d => d.campaigns),
      yAxisID: 'yAxis',
      order: 1,
    },
  ],
});

const legendOptions = { position: 'top' };

const graphOptions = {
  scales: {
    yAxis: {
      type: 'linear',
      position: 'left',
      title: { display: true, text: '# de campañas y de usuarios activos' },
    },
  },
};

const UsersAndCampaignsByMonthGraphNoMemo = ({ usersActivity }) => {

  const dataPreGraph = useMemo(() => {
    const dataForCompanies = usersActivity.reduce((p, c) => {
      if (!p[c.companyName]) {
        p[c.companyName] = { campaigns: {}, usersActivity: {} };
      }
      p[c.companyName].campaigns[c.campaignId] = c.campaignId;
      p[c.companyName].usersActivity[`${c.companyName}-${c.userEmail}`] = `${c.companyName}-${c.userEmail}`;
      return p;
    }, {});

    return Object.keys(dataForCompanies).map(k => ({
      company: k,
      campaigns: Object.keys(dataForCompanies[k].campaigns).length,
      usersActivity: Object.keys(dataForCompanies[k].usersActivity).length,
    })).sort((a, b) => b.usersActivity - a.usersActivity);
  }, [ usersActivity ]);

  return (
    <GraphWrapper
      title={`Campañas y usuarios activos por Empresa`}
      info={`Campañas y usuarios activos por Empresa`}
      dataPreGraph={ dataPreGraph }
      dataToGraphData={ dataToGraphData }
      chartjsComponent={ Chart }
      legend={ legendOptions }
      type='bar'
      options={ graphOptions }
    />
  );
};

const UsersAndCampaignsByMonthGraph = memo(UsersAndCampaignsByMonthGraphNoMemo,
  (prev, next) =>
    prev.usersActivity.length === next.usersActivity.length &&
    prev.usersActivity.every((b, i) =>
      b.companyName === next.usersActivity[i].companyName
      && b.campaignId === next.usersActivity[i].campaignId
      && b.userEmail === next.usersActivity[i].userEmail),
);

UsersAndCampaignsByMonthGraphNoMemo.propTypes = {
  usersActivity: PropTypes.array.isRequired,
};


export {
  UsersAndCampaignsByMonthGraph,
};