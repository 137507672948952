import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography, Collapse } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';
import { withAccessControl, DialogButton } from 'src/components';


const { NOT_STARTED, LOADING, SUCCESS, ERROR } = fetchStatusEnum;

const AcceptRequestDialog = props => {
  const { actions } = props;

  const [ fetchStatus, setFetchStatus ] = useState(NOT_STARTED);
  const [ errorAlert, setErrorAlert ] = useState({ isOpen: false, message: '' });

  const handleConfirm = async () => {
    try {
      setFetchStatus(LOADING);
      await actions.acceptRequest();
      setFetchStatus(SUCCESS);
      setTimeout(() => actions.closeDialog(), 300);
    } catch (e) {
      console.error(e);
      setErrorAlert({ isOpen: true, message: e.serverMessage || e.message });
      setFetchStatus(ERROR);
    }
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">Aceptar solicitud de empresa</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Typography variant="body1" color="textPrimary">
            Al aceptar esta solicitud, podrá compartir sus campañas y proyectos para que estos puedan ser
            visualizados en la plataforma de Nviro Monitor
          </Typography>
          <br />
          <Typography variant="body1" color="textPrimary">
            ¿Deseas aceptar la solicitud?
          </Typography>
          <br />
          {fetchStatus === ERROR &&
            <Collapse in={errorAlert.isOpen}>
              <Alert severity="error" onClose={() => setErrorAlert(s => ({ ...s, isOpen: false }))}>
                {errorAlert.message}
              </Alert>
            </Collapse>
          }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color="primary">Aceptar</DialogButton>
      </DialogActions>
    </>
  );
};

AcceptRequestDialog.propTypes = {
  actions: PropTypes.shape({
    acceptRequest: PropTypes.func,
    closeDialog: PropTypes.func,
  }),
};

const AcceptRequestDialogWithAccessControl = withAccessControl({ action: 'monitor:accept-request' })(AcceptRequestDialog);


export { AcceptRequestDialogWithAccessControl as AcceptRequestDialog };
