import { get, patch, post, del } from 'src/services/api';


const getLinkedCompanies = async () => (await get('company/monitor-companies')).data;

const getLinkRequests = async () => (await get('company/link-request')).data;

const getCompanyCampaigns = async params => (await get('company/export-allowed', params)).data;

const updateCampaignsToAllowExport = async params => (await patch('company/export-allowed-campaigns', params)).data;

const updateProjectsToAllowExport = async params => (await patch('company/export-allowed-projects', params)).data;

const insertLinkCode = async params => (await post('company/link-code', params)).data;

const acceptRequest = async params => (await post('company/accept-link-code', params)).data;

const deleteRequest = async requestId => (await del(`company/link-code/${requestId}`)).data;


export {
  getLinkedCompanies,
  getCompanyCampaigns,
  updateCampaignsToAllowExport,
  updateProjectsToAllowExport,
  insertLinkCode,
  getLinkRequests,
  acceptRequest,
  deleteRequest,
};