import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

import { TableWrapper } from 'src/components';

const UsersActivityByCampaignList = ({ usersActivity, personalCompanies, onlyOneCompany }) => {

  const tableColumns = [
    { title: 'Fila', field: 'rowNumber' },
    ...(personalCompanies || onlyOneCompany ? [] : [ { title: 'Empresa', field: 'companyName' } ]),
    { title: 'Proyecto', field: 'projectName' },
    { title: 'Campaña', field: 'campaignName' },
    { title: 'Componente', field: 'component' },
    ...(onlyOneCompany ? [] : [ { title: 'Id campaña', field: 'campaignId' } ]),
    { title: 'Email', field: 'userEmail' },
    { title: 'Nombre', field: 'userName' },
    { title: 'Conjuntos de tracks sincronizados', field: 'tracksSetsSynced' },
    { title: 'Unidades de muestreo sincronizados', field: 'samplingUnitsSynced' },
    { title: 'Metodologías sincronizadas', field: 'methodologiesSynced' },
    { title: 'Registros Sincronizados', field: 'recordSynced' },
    { title: 'Individuos sincronizados', field: 'individualSynced' },
    { title: 'Conjuntos de árboles sincronizados', field: 'treesSetsSynced' },
    { title: 'Total de sincronizaciones', field: 'totalSyncs' },
  ];

  return (
    <Box width="100%">
      <Typography variant="h4" color="primary">Actividad de los usuarios por campaña</Typography>
      <TableWrapper
        columns={tableColumns}
        data={usersActivity}
        options={ {
          pageSize: usersActivity.length >= 100 ? 100 : usersActivity.length >= 50 ? 50 : usersActivity.length >= 20 ? 20 : 10,
          pageSizeOptions: [ 10, 20, 50, 100 ],
          clientSorting: true,
          idSynonym: 'rowNumber',
        } }
      />
    </Box>
  );
};

UsersActivityByCampaignList.propTypes = {
  usersActivity: PropTypes.array,
  personalCompanies: PropTypes.bool,
  onlyOneCompany: PropTypes.bool,
};


export { UsersActivityByCampaignList };
