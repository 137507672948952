import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography, Collapse } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';
import { withAccessControl, DialogButton } from 'src/components';


const { NOT_STARTED, LOADING, SUCCESS, ERROR } = fetchStatusEnum;

const DeclineRequestDialog = props => {
  const { actions } = props;

  const [ fetchStatus, setFetchStatus ] = useState(NOT_STARTED);
  const [ errorAlert, setErrorAlert ] = useState({ isOpen: false, message: '' });

  const handleConfirm = async () => {
    try {
      setFetchStatus(LOADING);
      await actions.deleteRequest();
      setFetchStatus(SUCCESS);
      setTimeout(() => actions.closeDialog(), 300);
    } catch (e) {
      console.error(e);
      setErrorAlert({ isOpen: true, message: e.serverMessage || e.message });
      setFetchStatus(ERROR);
    }
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">Rechazar solicitud</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Typography variant="body1" color="textPrimary">
            Al rechazar esta solicitud, deberá ingresar nuevamente el código de vinculación al momento de permitir visualizar sus datos
            en la plataforma de Nviro Monitor.
          </Typography>
          <br />
          {fetchStatus === ERROR &&
            <Collapse in={errorAlert.isOpen}>
              <Alert severity="error" onClose={() => setErrorAlert(s => ({ ...s, isOpen: false }))}>
                {errorAlert.message}
              </Alert>
            </Collapse>
          }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color="primary">Rechazar solicitud</DialogButton>
      </DialogActions>
    </>
  );
};

DeclineRequestDialog.propTypes = {
  actions: PropTypes.shape({
    deleteRequest: PropTypes.func,
    closeDialog: PropTypes.func,
  }),
};

const DeclineRequestDialogWithAccessControl = withAccessControl({ action: 'monitor:delete-request' })(DeclineRequestDialog);


export { DeclineRequestDialogWithAccessControl as DeclineRequestDialog };
