import urlJoin from 'url-join';
import config from 'src/config/local';

import { get, del, patch, post } from 'src/services/api';


const getCompanies = async () => (await get('company')).data;

const getSelectableCompanies = async () => (await get('company/selectables')).data;

const create = async newCompany => (await post('company', newCompany)).data;

const update = async (companyId, companyToUpdate) => (await patch(`company/${companyId}`, companyToUpdate)).data;

const remove = async companyId => (await del(`company/${companyId}`)).data;

const changeCompany = async companyId => (await get(`company/${companyId}/change`)).data;

const getEnvironments = async () => (await get(`company/environments`)).data;

const createEnvironment = async newEnvironment => (await post('company/environments', newEnvironment)).data;

const updateEnvironment = async (environmentId, environmentToUpdate) =>
  (await patch(`company/environments/${environmentId}`, environmentToUpdate)).data;

const removeEnvironment = async environmentId => (await del(`company/environments/${environmentId}`)).data;

const getUsersActivityByCampaign = async ({ year, month, personalCompanies, showAllCompanies }) =>
  (await get('company/users-activity-by-campaign', { year, month, personalCompanies, showAllCompanies })).data;

const buildUsersActivityByCampaignExportUrl = ({ year, month, showAllCompanies }) => urlJoin(
  config.serverUrl,
  `api/company/users-activity-by-campaign?year=${year}&month=${month}&exportFile=true&showAllCompanies=${showAllCompanies || false}`,
);

const getActiveUsersCounterAllTime = async ({ showAllCompanies }) =>
  (await get('company/active-users-counters-all-time', { showAllCompanies })).data;

const getUsersSyncCounterByDayAcc = async ({ year, month, personalCompanies, showAllCompanies }) =>
  (await get('company/users-sync-counter-by-day-acc', { year, month, personalCompanies, showAllCompanies })).data;

const getActiveUsers = async ({ year, month, showAllCompanies }) =>
  (await get('company/active-users', { year, month, showAllCompanies })).data;

const getLogos = async () => (await get('company/logos')).data;


export {
  getCompanies,
  create,
  update,
  remove,
  changeCompany,
  getSelectableCompanies,
  getEnvironments,
  createEnvironment,
  updateEnvironment,
  removeEnvironment,
  getUsersActivityByCampaign,
  buildUsersActivityByCampaignExportUrl,
  getActiveUsersCounterAllTime,
  getUsersSyncCounterByDayAcc,
  getActiveUsers,
  getLogos,
};
