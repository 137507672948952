import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Box, Typography, useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router';
import * as Scroll from 'react-scroll';

import { NviroTextField } from 'src/scenes/Home/components';
import { CategoryCard } from 'src/scenes/Home/scenes/Contact/components';
import { usersApi } from 'src/services';
import { NviroButton } from 'src/components';
import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';
import { AlertWrapper as Alert } from 'src/components';


const Element = Scroll.Element;
const scroller = Scroll.scroller;

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.common.white,
  },
  categoriesSection: {
    paddingTop: theme.spacing(10),
    paddingLeft: theme.spacing(30),
    paddingRight: theme.spacing(30),
    paddingBottom: theme.spacing(22),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(5),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(5),
    },
  },
  categoriesTitle: {
    fontSize: '4.375rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
      lineHeight: '58px',
      textAlign: 'center',
    },
  },
  categoriesSubtitle: {
    fontSize: '1.875rem',
    color: theme.palette.common.black,
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
    },
  },
  generalInquirySection: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(11),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(5),
    },
  },
  generalInquiryTitle: {
    paddingBottom: theme.spacing(3),
    color: theme.palette.common.gray600,
    fontSize: '3.75rem',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '3.125rem',
      lineHeight: '58px',
      textAlign: 'center',
    },
  },
  generalInquiryImage: {
    height: '500px',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      height: 'unset',
      marginTop: theme.spacing(5),
    },
  },
  generalInquiryForm: {
    width: '448px',
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: '280px',
      paddingRight: theme.spacing(0),
    },
  },
  generalInquiryInput: {
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(4),
    },
  },
  alertsContainer: {
    height: '6.5em',
  },
}));

const stopOnSubmit = e => { e.preventDefault(); };

const ContactContainer = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [ inquiry, setInquiry ] = useState({ email: '', name: '', phone: '', company: '', message: '' });
  const [ fetchStatus, setFetchStatus ] = useState(fetchStatusEnum.NOT_STARTED);
  const [ errorMsg, setErrorMsg ] = useState('');

  const { email, name, phone, company, message } = inquiry;
  const handleChangeInquiry = e => setInquiry({ ...inquiry, [e.target.name]: e.target.value });

  useEffect(() => {
    document.title = 'Contacto - Nviro Capture';
  }, []);

  const inquiryCommonProps = {
    className: classes.generalInquiryInput,
    onChange: handleChangeInquiry,
    size: isDownSm ? 'small' : 'medium',
  };

  const handleSubmit = async () => {
    if (fetchStatus === fetchStatusEnum.LOADING) {
      return;
    }
    setFetchStatus(fetchStatusEnum.LOADING);
    setErrorMsg(null);
    try {
      const { name, email, message, phone, company } = inquiry;
      await usersApi.contact({ userName: name, userEmail: email, message, phone, company, service: 'nviro' });
      setFetchStatus(fetchStatusEnum.SUCCESS);
      setInquiry({ email: '', name: '', phone: '', company: '', message: '' });
    } catch (e) {
      console.error(e);
      setFetchStatus(fetchStatusEnum.ERROR);
      setErrorMsg(e.serverMessage || 'Hubo un error, por favor intente más tarde');
    }
  };

  const loading = fetchStatus === fetchStatusEnum.LOADING;
  const succeeded = fetchStatus === fetchStatusEnum.SUCCESS;
  return (
    <Box className={classes.container}>
      <Grid className={classes.categoriesSection} container alignItems="center" justifyContent="center" direction="column">
        <Grid item>
          <Typography className={classes.categoriesTitle} color="primary">¿Cómo te podemos ayudar?</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.categoriesSubtitle}>
            Elige una categoría
          </Typography>
        </Grid>
        <Grid container alignItems="center" justifyContent="center" spacing={4}>
          <Grid item>
            <CategoryCard
              title="Consulta general"
              description="Indícanos cualquier duda, sugerencia, propuesta o reclamo que tengas sobre Nviro Capture."
              Icon={() => <img src="/img/home/speak.svg" alt="icon-one" height="40px" />}
              onClick={() => scroller.scrollTo('contact', { duration: 500, smooth: true })}
            />
          </Grid>
          <Grid item>
            <CategoryCard
              title="Solicitar una demostración"
              description="Agenda con nosotros una demostración de Nviro Capture para conocer a fondo sus funcionalidades."
              Icon={() => <img src="/img/home/computer.svg" alt="icon-one" height="40px" />}
              onClick={() => history.push('/schedule-demo')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.generalInquirySection} container alignItems="center" direction="column">
        <Grid item>
          <Element name="contact">
            <Typography className={classes.generalInquiryTitle} color="primary">Contáctanos</Typography>
          </Element>
        </Grid>
        <Grid item container alignItems="center" justifyContent="center">
          <Grid item>
            <Box className={classes.generalInquiryForm} display="flex" alignItems="center" flexDirection="column">
              {/* form, botón type=submit y autocomplete para que se acuerde de los inputs*/}
              <form autoComplete="on" noValidate onSubmit={ stopOnSubmit }>
                <NviroTextField disabled={loading} color={ loading ? 'grey' : 'primary' } autoComplete="on"
                  value={name} id="name-field" name="name" label="Nombre" {...inquiryCommonProps} />
                <NviroTextField disabled={loading} color={ loading ? 'grey' : 'primary' } autoComplete="on"
                  value={email} id="email-field" name="email" label="Correo" {...inquiryCommonProps} />
                <NviroTextField disabled={loading} color={ loading ? 'grey' : 'primary' } autoComplete="on"
                  value={company} id="company-field" name="company" label="Empresa" {...inquiryCommonProps} />
                <NviroTextField disabled={loading} color={ loading ? 'grey' : 'primary' } autoComplete="on"
                  value={phone} id="phone-field" name="phone" label="Teléfono" {...inquiryCommonProps} />
                <NviroTextField disabled={loading} color={ loading ? 'grey' : 'primary' } autoComplete="on"
                  value={message} id="message-field" name="message" label="Mensaje" minRows={5} multiline {...inquiryCommonProps} />
                <NviroButton
                  type="submit"
                  loading={loading}
                  size={isDownSm ? 'small' : 'medium'}
                  className={classes.generalInquiryButton}
                  color="secondary"
                  onClick={handleSubmit}
                >
                  Enviar
                </NviroButton>
              </form>
              {/* container con altura, para que los alerts no empujen y retraigan el footer cuando aparecen y desaparecen */}
              <Box className={classes.alertsContainer}>
                <Alert BoxProps={ { mt: 2 } } severity="success" isOpen={succeeded}>
                  Tu mensaje ha sido enviado, muchas gracias por tu interés en Nviro Capture
                </Alert>
                <Alert BoxProps={ { mt: 2 } } severity="error" isOpen={fetchStatus === fetchStatusEnum.ERROR}>
                  { errorMsg }
                </Alert>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Box display="flex" width="100%" justifyContent="center">
              <img className={classes.generalInquiryImage} src="/img/home/contact.jpg" alt="contacto" />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};


export { ContactContainer };
