import React, { useContext, useEffect, useState } from 'react';
import { Container, Button, TextField, Box, Paper, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router';
import WarningIcon from '@material-ui/icons/Warning';

import { usersApi } from 'src/services';
import { WebContext } from 'src/scenes/web-context';
import { StaticSnackbar, TitleContainer, ContentContainer, EmailVerificationAlert, DialogWrap } from 'src/components';
import { DeleteAccountDialog } from 'src/scenes/Profile/components';


const useStyles = makeStyles(theme => ({
  dangerTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dangerText: {
    color: theme.palette.danger.main,
  },
  dangerIcon: {
    marginLeft: '5px',
    color: theme.palette.danger.main,
  },
  deleteButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.danger.main,
  },
}));

const ProfileContainer = () => {

  const classes = useStyles();
  const history = useHistory();
  const { currentUser: { firstName, lastName, email, emailVerified } } = useContext(WebContext);

  const [ userInfo, setUserInfo ] = useState({ password: '', repassword: '' });

  const [ submitted, setSubmitted ] = useState(false);
  const [ ok, setOk ] = useState(false);

  const [ dialog, setDialog ] = useState({
    isOpen: false,
    data: {},
    type: '',
  });

  useEffect(() => {
    document.title = 'Perfil - Nviro Capture';
  }, []);

  const handleSubmit = async () => {
    const { password, repassword } = userInfo;
    setSubmitted(true);
    if (password === repassword && password.length >= 8) {
      await usersApi.updateUser({ password });
      setOk(true);
    }
  };

  const handleChange = e => setUserInfo(pui => ({ ...pui, [e.target.name]: e.target.value }));

  const deleteAccount = async () => {
    await usersApi.deleteAccount();
    history.push(`/`);
    localStorage.clear();
  };

  const openDialog = type => setDialog(pd => ({ ...pd, isOpen: true, type }));

  const closeDialog = () => setDialog(pd => ({ ...pd, isOpen: false, type: '' }));

  const getDialogType = () => {
    const commonActions = { closeDialog, deleteAccount };
    switch (dialog.type) {
      case 'accountDeletion':
        return <DeleteAccountDialog
          actions={{ ...commonActions }}
        />;
      default:
        break;
    }
  };

  const onPressAccountDeletion = () => openDialog('accountDeletion');

  const { password, repassword } = userInfo;

  return (
    <>
      <Grid container>
        <TitleContainer>Perfil de usuario</TitleContainer>
        <ContentContainer>
          <Container maxWidth="md">
            <Box py={3} px={5}>
              <Paper style={{ paddingTop: '24px', paddingBottom: '24px' }}>
                <EmailVerificationAlert mt={1} mb={2} px={2}/>

                <Box ml={3} mb={3}>
                  <Typography variant="h5" color="textPrimary">Datos</Typography>
                </Box>
                <Grid container>
                  <Grid item md={4} sm={6} xs={12} >
                    <Box mx={4}>
                      <TextField fullWidth disabled label="Nombre" defaultValue={firstName} margin="normal" />
                    </Box>
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <Box mx={4}>
                      <TextField fullWidth disabled label="Apellido" defaultValue={lastName} margin="normal" />
                    </Box>
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <Box mx={4}>
                      <TextField fullWidth disabled label="Email" defaultValue={email} margin="normal" />
                    </Box>
                  </Grid>
                </Grid>

                {
                  emailVerified &&
                  <>
                    <Box ml={3} mt={8}>
                      <Typography variant="h5" color="textPrimary">Cambiar contraseña</Typography>
                    </Box>
                    <Grid container>
                      <Grid item md={4} sm={6} xs={12}>
                        <Box mx={4}>
                          <TextField fullWidth label="Nueva contraseña" type="password" margin="normal" name="password"
                            onChange={handleChange} value={password} />
                        </Box>
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <Box mx={4}>
                          <TextField fullWidth label="Confirmar contraseña" type="password" margin="normal" name="repassword"
                            onChange={handleChange} value={repassword} />
                        </Box>
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <Box mx={4} mt={3} >
                          <Button color="primary" variant="contained" onClick={handleSubmit} >Cambiar contraseña</Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        {submitted && !password &&
                          <Box mx={4} mt={1}><StaticSnackbar variant="error" message="Debes ingresar una contraseña" /> </Box>}
                        {
                          submitted && password.length < 8 &&
                          <Box mx={4} mt={1}>
                            <StaticSnackbar variant="error" message="La contraseña debe tener más de 8 caracteres" />
                          </Box>
                        }
                        {submitted && password !== repassword &&
                          <Box mx={4} mt={1}><StaticSnackbar variant="error" message="Las contraseñas deben coincidir" /></Box> }
                        {ok &&
                          <Box mx={4} mt={1}><StaticSnackbar variant="success" message="Cambio de contraseña exitoso" /> </Box>}
                      </Grid>
                    </Grid>
                  </>
                }

                {
                  emailVerified &&
                  <>
                    <Box ml={3} mt={8} className={ classes.dangerTitleContainer }>
                      <Typography variant="h5" className={ classes.dangerText }><strong>Eliminación de Datos</strong></Typography>
                      <WarningIcon className={ classes.dangerIcon }/>
                    </Box>
                    <Grid container>
                      <Grid item md={4} sm={6} xs={12}>
                        <Box mx={4} mt={3} >
                          <Button variant="contained" className={ classes.deleteButton } onClick={onPressAccountDeletion}>
                            Eliminar cuenta
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                }
              </Paper>
            </Box>
          </Container>
        </ContentContainer>
        <DialogWrap maxWidth='xs' fullWidth onClose={dialog.closeDialog} open={dialog.isOpen}>
          { getDialogType() }
        </DialogWrap>
      </Grid>
    </>);
};

export {
  ProfileContainer,
};
