import React, { useState, useEffect } from 'react';
import { Grid, Container, Box, Select, MenuItem, Button, Card, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { TitleContainer, ContentContainer, TableLoader, AlertWrapper } from 'src/components';
import { companiesApi } from 'src/services';
import { monthNames, prependZero } from 'src/utils/util';
import { ActiveUsersList, ActiveUsersSummaryAllTimeGraph, UsersActivityByCampaignList, UsersActivitySummary }
  from 'src/components/companyUsage';
import { upperCaseOnlyFirstLetter } from 'src/utils/formatters';
import { MicrosoftExcel } from 'src/utils/mdIcons';


const useStyles = makeStyles(() => ({
  allTimeGraphContainer: {
    height: '500px',
  },
}));

const INITIAL_YEAR = 2023;
const currentDate = new Date();
const [ , currentYear, currentMonth ] = currentDate.toISOString().match(/^(\d{4})-(\d{2})-\d{2}/);
const UsageContainer = () => {

  const classes = useStyles();

  const [ year, setYear ] = useState(currentYear);
  const [ month, setMonth ] = useState(currentMonth);
  const [ usersActivity, setUsersActivity ] = useState([]);
  const [ activeUsers, setActiveUsers ] = useState([]);

  useEffect(() => document.title = 'Uso de la plataforma - Nviro Capture', []);

  return (
    <Grid container >
      <TitleContainer maxWidth="xl">
        Uso de la plataforma
      </TitleContainer>
      <ContentContainer maxWidth="xl">
        <Container maxWidth="xl">
          <Box mt={ 1 }>
            <AlertWrapper severity="info">
              Para el conteo de usuarios activos y su actividad se toma en cuenta lo siguiente:
              <ul>
                <li>El usuario debe haber sincronizado algún dato distinto de un conjunto tracks durante el mes</li>
                <li>No cuenta para el conteo la sincronización de estaciones de muestreo que no tengan datos</li>
              </ul>
            </AlertWrapper>
          </Box>
          <Box mt={ 1 } mb={ 1 }>
            <Typography variant="h4" color="primary">Evolución en el tiempo de usuarios en la empresa</Typography>
            <Card className={ classes.allTimeGraphContainer }>
              <ActiveUsersSummaryAllTimeGraph />
            </Card>
          </Box>
          <Box my={ 3 }><Divider /></Box>
          <Typography variant="h4" color="primary">Resumen de {upperCaseOnlyFirstLetter(monthNames[month - 1])}</Typography>
          <Box mb={ 1 } flexDirection='row' display='flex' justifyContent='space-between'>
            <Box>
              <Select value={month} onChange={e => setMonth(e.target.value)}>
                {monthNames.map((name, idx) =>
                  <MenuItem key={idx} value={prependZero(idx + 1)}>{upperCaseOnlyFirstLetter(name)}</MenuItem>)
                }
              </Select>
              <Select value={ year } onChange={e => setYear(e.target.value)}>
                {
                  new Array(currentDate.getFullYear() - INITIAL_YEAR + 1).fill().map((_, i) => INITIAL_YEAR + i)
                    .map(year => <MenuItem key={year} value={`${year}`}>{year}</MenuItem>)
                }
              </Select>
            </Box>
            <Button
              size="small"
              href={ companiesApi.buildUsersActivityByCampaignExportUrl({ year, month }) }
              target="_blank"
              variant="outlined"
              color="primary"
              startIcon={<MicrosoftExcel />}
            >
              Exportar actividad de usuarios
            </Button>
          </Box>
          { usersActivity.length > 0 && <UsersActivitySummary usersActivity={ usersActivity } onlyOneCompany />}
          <TableLoader
            apiCall={ companiesApi.getActiveUsers }
            data={ { year, month } }
            onSuccess={ users => setActiveUsers(users) }
            onError={ () => setActiveUsers([]) }
          >
            <ActiveUsersList activeUsers={ activeUsers } onlyOneCompany />
          </TableLoader>
          <Box mt={2} mb={ 2 }>
            <TableLoader
              apiCall={ companiesApi.getUsersActivityByCampaign }
              data={ { year, month } }
              onSuccess={ activity => setUsersActivity(activity) }
              onError={ () => setUsersActivity([]) }
            >
              <UsersActivityByCampaignList usersActivity={ usersActivity } onlyOneCompany/>
            </TableLoader>
          </Box>
        </Container>
      </ContentContainer>
    </Grid>
  );
};


export {
  UsageContainer,
};
