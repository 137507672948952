import { useContext } from 'react';
import { WebContext } from 'src/scenes/web-context';
import PropTypes from 'prop-types';
import { checkPermission } from 'src/utils/checkPermission';
import { isActiveSub, isTransitionPeriod } from 'src/utils/checkers';


const AccessControl = ({
  action, extraCondition, noAccessRender, children, checkOnCampaignRole, notEvenGods, campaignRole,
  checkEmailVerified, isSubscriptionRequired,
}) => {
  const webContext = useContext(WebContext);

  let finalExtraCondition = extraCondition;
  if (checkEmailVerified) {
    finalExtraCondition = finalExtraCondition && webContext.currentUser?.emailVerified;
  }
  if (isSubscriptionRequired) {
    const company = webContext.selectedCompany;
    const isPersonalAccount = Boolean(webContext.selectedCompany.private);

    finalExtraCondition = finalExtraCondition && (
      isTransitionPeriod(company?.nviroPayCache)
      || isActiveSub(company?.nviroPayCache?.subscriptionStatus)
      || isPersonalAccount // usuarios fan tienen "vía libre" hasta que se programen suscripciones para ellos
    );
  }

  // if you call this with no action, it just checks the extra conditions (and emailVerified if necessary)
  const canAccess = (
    !action ? finalExtraCondition
    : checkPermission({ action, extraCondition: finalExtraCondition, checkOnCampaignRole, notEvenGods, campaignRole, webContext })
  );


  return canAccess ? children : noAccessRender({ webContext });
};

AccessControl.defaultProps = {
  noAccessRender: () => null,
  extraCondition: true,
  checkEmailVerified: false,
  isSubscriptionRequired: false,
};

AccessControl.propTypes = {
  action: PropTypes.string,
  noAccessRender: PropTypes.func,
  extraCondition: PropTypes.bool,
  checkOnCampaignRole: PropTypes.bool,
  notEvenGods: PropTypes.bool,
  checkEmailVerified: PropTypes.bool,
};


export {
  AccessControl,
};