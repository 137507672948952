const pricing = {
  1: {
    button: {
      text: 'Contáctanos',
      link: '/contact',
      color: 'blue',
    },
    features: {
      data: [
        'Hasta 3 usuarios activos al mes',
        '10 GB de almacenamiento',
        'Soporte personalizado',
        'Inducción personalizada',
      ],
    },
    notFeatures: {
      data: [
        'Almacenamiento ilimitado',
      ],
    },
    note: '',
  },
  2: {
    button: {
      text: 'Contáctanos',
      link: '/contact',
      color: 'blue',
    },
    features: {
      text: '',
      data: [
        'Hasta 7 usuarios activos al mes',
        '20 GB de almacenamiento',
        'Soporte personalizado',
        'Inducción personalizada',
      ],
    },
    notFeatures: {
      data: [],
    },
    note: '',
  },
  3: {
    button: {
      text: 'Contáctanos',
      link: '/contact',
      color: 'primary',
    },
    features: {
      text: '',
      data: [
        'Hasta 11 usuarios activos al mes',
        '50 GB de almacenamiento',
        'Soporte personalizado',
        'Inducción personalizada',
      ],
    },
    notFeatures: {
      data: [],
    },
    // note: '* Los precios varían según sus requerimientos',
  },
};

const faq = [
  {
    question: '¿Cómo crear una cuenta?',
    answer: `Lorem ípsum dolorursu, malesuada erat ut, egestas odio. Quisque suscipit, urna ac vulputate sollicitudin, mi urna
      elementum augue, id tristique arcu erat non enim. Lorem ípsum dolor sit amet, consectetur adipiscing elit. Integer non
      leo pretium pellentesque. Curabitur blandit urna cursus, malesuada erat ut, egestas.`,
  },
  {
    question: '¿Cómo puedo contratar sus servicios?',
    answer: `Lorem ípsum dolorcursus, malesuada erat ut, egestas odio. Quisque suscipit, urna ac vulputate sollicitudin, mi urna
      elementum augue, id tristique arcu erat non enim. Lorem ípsum dolor sit amet, consectetur adipiscing elit. Integer non
      leo pretium pellentesque. Curabitur blandit urna cursus, malesuada erat ut, egestas.`,
  },
  {
    question: '¿Puedo cancelar la suscripción en cualquier momento?',
    answer: `Lorem ípsum dolor sit amet, consectetur adipiscing elit. Integer euismod odio non leo pretium pellentesque.
      Curabitur blandit urna cursus, malesuada erat ut, egestas odio. Quisque suscipit, urna ac vulputate sollicitudin, mi urna
      elementum augue, id tristique arcu erat non enim. Lorem ípsum dolor sit amet, consectetur adipiscing elit. Integer non
      leo pretium pellentesque. Curabitur blandit urna cursus, malesuada erat ut, egestas.`,
  },
  {
    question: '¿Mis datos estaran seguros?',
    answer: `Lorem ípsum dolor sit amet, consectetur adipiscing elit. Integer euismod odio non leo pretium pellentesque.
      Curabitur blandite, id tristique arcu erat non enim. Lorem ípsum dolor sit amet, consectetur adipiscing elit. Integer non
      leo pretium pellentesque. Curabitur blandit urna cursus, malesuada erat ut, egestas.`,
  },
  {
    question: '¿Cual es el beneficio de un plan anual?',
    answer: `Lorem ípsum dolor sit amet, consectetur adipiscing elit. Integer euismod odio non leo pretium pellentesque.
      Curabitur blandit urna cursus, ma tristique arcu erat non enim. Lorem ípsum dolor sit amet, consectetur adipiscing elit. Integer non
      leo pretium pellentesque. Curabitur blandit urna cursus, malesuada erat ut, egestas.`,
  },
  {
    question: '¿Que ocurre con mis datos si acaba mi suscripción?',
    answer: `Lorem ípsum dolor sit amet, coon enim. Lorem ípsum dolor sit amet, consectetur adipiscing elit. Integer non
      leo pretium pellentesque. Curabitur blandit urna cursus, malesuada erat ut, egestas.`,
  },
  {
    question: '¿Que ocurre con mis datos si acaba mi suscripción?',
    answer: `Lorem ípsum dolor sarcu erat non enim. Lorem ípsum dolor sit amet, consectetur adipiscing elit. Integer non
      leo pretium pellentesque. Curabitur blandit urna cursus, malesuada erat ut, egestas.`,
  },
  {
    question: '¿Que medios de pago se aceptan?',
    answer: `Lorem ípsum dolor sit amet, consectetur adipiscing elit. Integer euismod odio non leo pretium pellentesque.
      Curabitur blandit urna cursus, malesuada erat .`,
  },
  {
    question: '¿Cómo puedo actualizar mi plan?',
    answer: `Lorem ípsum dolor sit amet, consectetur adipiscing elit. Integer euismod odio non leo pretium pellentesque.
      Curabitur blandit urna cursus, malesuada erat .`,
  },
];

const featuresByPlan = {
  'Características': [
    { name: 'Usuarios activos al mes', plan: { 1: 3, 2: 7, 3: 11, 4: 'Personalizado' } },
    { name: 'Campañas mensuales', plan: { 1: 'Ilimitadas', 2: 'Ilimitadas', 3: 'Ilimitadas', 4: 'Personalizado' } },
    { name: 'Almacenamiento', plan: { 1: '10GB', 2: '20GB', 3: '50GB', 4: 'Personalizado' } },
    { name: 'Soporte personalizado', plan: { 1: true, 2: true, 3: true, 4: true } },
    { name: 'Onboarding personalizado', plan: { 1: true, 2: true, 3: true, 4: true } },
  ],
};


export { pricing, faq, featuresByPlan };
