import React, { memo } from 'react';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';

import { GraphWrapper } from 'src/components';
import { prependZero } from 'src/utils/util';
import { companiesApi } from 'src/services';


const getLabels = () => {
  const firstDate = { year: 2023, month: 6 }; // fecha en la cual se comenzó el seguimiento más detallado de cobros
  const currentDate = new Date();
  const lastDate = { year: currentDate.getFullYear(), month: currentDate.getMonth() };

  const labels = [];
  for (let year = firstDate.year; year <= lastDate.year; year++) {
    for (let month = year === firstDate.year ? firstDate.month : 0; month <= 11; month++) {
      if (year === lastDate.year && month > lastDate.month) {
        break;
      }
      labels.push(`${year}-${prependZero(month + 1)}`);
    }
  }

  return labels;
};

const allCompaniesLabel = 'Todas las empresas';
const dataToGraphData = data => {
  const minDateInData = data.map(companyData => companyData.data[0].yearMonth).sort()?.[0] || '2023-07';

  return {
    labels: getLabels().filter(yearMonth => yearMonth >= minDateInData),
    datasets: data.map(({ companyName, data }) => ({
      label: companyName,
      data: data.map(({ yearMonth, activeUsers }) => ({ x: yearMonth, y: activeUsers })),
      yAxisID: 'yAxis',
      pointStyle: 'circle',
      pointRadius: 7,
      pointHoverRadius: 14,
      hidden: companyName === allCompaniesLabel,
    })),
  };
};

const legendOptions = { position: 'top' };

const onClickLegend = (e, legendItem, legend) => {
  const index = legendItem.datasetIndex;
  const ci = legend.chart;
  const datasets = ci.data.datasets;

  const allShowLessAll = ci.data.datasets
    .every(({ label }, i) => label !== allCompaniesLabel ? ci.isDatasetVisible(i) : !ci.isDatasetVisible(i));
  const allHiddenLessCurrent = ci.data.datasets.every((_, i) => i === index ? ci.isDatasetVisible(i) : !ci.isDatasetVisible(i));

  if (datasets.length > 3 && allShowLessAll && legendItem.text !== allCompaniesLabel) {
    // mostrar solo compañía clickeada si las demás también se están mostrando
    ci.data.datasets
      .forEach(({ label }, i) => {
        if (label !== allCompaniesLabel && label !== legendItem.text) {
          ci.hide(i);
        }
      });
  } else if (datasets.length > 3 && allHiddenLessCurrent) {
    // mostrar todo si se pulso la única empresa que estaba visible
    ci.data.datasets
      .forEach(({ label }, i) => {
        if (label !== 'Todas las empresas') {
          ci.show(i);
        }
      });
  } else {
    // comportamiento default
    if (ci.isDatasetVisible(index)) {
      ci.hide(index);
      legendItem.hidden = true;
    } else {
      ci.show(index);
      legendItem.hidden = false;
    }
  }
};

const graphOptions = {
  scales: {
    yAxis: {
      type: 'linear',
      position: 'left',
      title: { display: true, text: '# de usuarios activos' },
      min: 0,
    },
  },
  plugins: {
    legend: {
      onClick: onClickLegend,
    },
  },
};

const ActiveUsersSummaryAllTimeGraphNoMemo = ({ showAllCompanies }) => {
  const apiCall = () => companiesApi.getActiveUsersCounterAllTime({ showAllCompanies });

  return (
    <GraphWrapper
      title={ showAllCompanies ? `Evolución en el tiempo de usuarios por empresas` : ''}
      info={
        showAllCompanies ?
          `
            En el gráfico cada línea representa una empresa distinta y el valor en el eje Y es la cantidad 
            de usuarios activos que tuvo el mes en particular desde el julio del año 2023.
            Este gráfico incluye a los usuarios fan.
          `
          : 'El gráfico muestra los usuarios activos que ha tenido la empresa desde el 1er mes de funcionamiento hasta la fecha actual'
      }
      apiCall={ apiCall }
      dataToGraphData={ dataToGraphData }
      chartjsComponent={ Line }
      legend={ legendOptions }
      options={ graphOptions }
    />
  );
};

ActiveUsersSummaryAllTimeGraphNoMemo.propTypes = {
  showAllCompanies: PropTypes.bool,
};

const ActiveUsersSummaryAllTimeGraph = memo(ActiveUsersSummaryAllTimeGraphNoMemo);


export {
  ActiveUsersSummaryAllTimeGraph,
};