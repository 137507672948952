import React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import { NviroButton } from 'src/components';
import { Check } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  paper: {
    width: '320px',
    margin: theme.spacing(2),
    padding: theme.spacing(4),
    height: 'fill-available',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: ({ disabled }) => disabled ? 0.8 : 1,
    filter: ({ disabled }) => disabled ? 'grayscale(1)' : 'unset',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
      marginBottom: theme.spacing(2),
      height: 'unset',
      width: '100%',
    },
  },
  title: {
    fontSize: '2.313rem',
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  description: {
    paddingTop: theme.spacing(3),
    fontSize: '1rem',
    textAlign: 'center',
  },
  button: {
    height: '3rem',
    fontWeight: 'bold',
    width: theme.spacing(29),
  },
  icon: {
    fontWeight: 'bold',
  },
  feature: {
    fontSize: '1rem',
  },
  featuresContainer: {
    [theme.breakpoints.down('sm')]: {
      height: 'unset',
    },
  },
  contactText: {
    paddingTop: theme.spacing(3),
    fontSize: '1rem',
    textAlign: 'center',
  },
}));

const CustomPricingCard = () => {

  const disabled = false;
  const classes = useStyles({ disabled });
  const history = useHistory();

  return (
    <Box className={classes.container} display="flex" alignItems="center">
      <Paper className={classes.paper} elevation={5}>
        <Typography color="primary" className={classes.title}>Personalizado</Typography>
        <Box>
          <Typography color="textPrimary" className={classes.description}>
            ¿Necesitas algo más?
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="center" textAlign="center" height={157}>
          <Typography color="textPrimary" className={classes.contactText}>
            Contáctanos y te ayudaremos a encontrar la mejor solución para tu negocio.
          </Typography>
        </Box>
        <Box pt={3}>
          <NviroButton color="blue" className={classes.button} onClick={() => history.push('/contact')}>
            Contáctanos
          </NviroButton>
        </Box>
        <Box display="flex" className={classes.featuresContainer}
          flexGrow={1} pt={4} alignItems="center" flexDirection="column" justifyContent="flex-start" maxWidth={'280px'}>
          {[ 'Los usuarios activos que necesites', 'El almacenamiento que necesites' ].map((feat, index) =>
            <Box key={index} display="flex" py={1} width="100%" alignItems="center">
              <Box pr={2} display="flex" alignItems="center">
                <Check className={classes.icon} color="primary" />
              </Box>
              <Box flexGrow={1}>
                <Typography color="textPrimary" className={classes.feature}>{feat}</Typography>
              </Box>
            </Box>,
          )}
        </Box>
      </Paper>
    </Box>
  );
};

CustomPricingCard.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  note: PropTypes.string,
  features: PropTypes.object,
};


export { CustomPricingCard };