import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

import { TableWrapper } from 'src/components/table/TableWrapper';


const ActiveUsersList = ({ activeUsers, personalCompanies, onlyOneCompany }) => {

  const tableColumns = [
    { title: 'Usuario activo', field: 'userNumber' },
    ...(personalCompanies || onlyOneCompany ? [] : [ { title: 'Empresa', field: 'companyName' } ]),
    { title: 'Email', field: 'userEmail' },
    { title: 'Nombre', field: 'userName' },
    { title: '1ra sincronización', field: 'firstSync' },
    { title: 'Campañas con actividad', field: 'campaignsWithActivity' },
    { title: 'Total de sincronizaciones', field: 'totalSyncs' },
  ];

  return (
    <Box width="100%">
      <Typography variant="h4" color="primary">Usuarios activos</Typography>
      <TableWrapper
        columns={tableColumns}
        data={activeUsers}
        options={ {
          pageSize: activeUsers.length >= 100 ? 100 : activeUsers.length >= 50 ? 50 : activeUsers.length >= 20 ? 20 : 10,
          pageSizeOptions: [ 10, 20, 50, 100 ],
          clientSorting: true,
          idSynonym: 'userNumber',
        } }
      />
    </Box>
  );
};

ActiveUsersList.propTypes = {
  activeUsers: PropTypes.array,
  personalCompanies: PropTypes.bool,
  onlyOneCompany: PropTypes.bool,
};


export { ActiveUsersList };
