import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, TextField, Collapse } from '@material-ui/core';

import { DialogButton, withAccessControl } from 'src/components';
import { fetchStatusEnum } from 'src/utils/enums';
import { Alert } from '@material-ui/lab';


const { NOT_STARTED, LOADING, SUCCESS, ERROR } = fetchStatusEnum;

const InsertLinkCodeDialog = ({ actions }) => {

  const [ linkCode, setLinkCode ] = useState('');
  const [ errorState, setErrorState ] = useState({ error: false, helperText: '' });

  const [ fetchStatus, setFetchStatus ] = useState(NOT_STARTED);
  const [ errorAlert, setErrorAlert ] = useState({ isOpen: false, message: '' });

  const handleSubmit = async () => {
    if (linkCode.length === 0) {
      setErrorState({ error: true, helperText: 'Ingrese código de vinculación' });
    } else {
      setFetchStatus(LOADING);
      try {
        await actions.sendLinkCode(linkCode);
        setFetchStatus(SUCCESS);
        actions.closeDialog();
      } catch (e) {
        setErrorAlert({ isOpen: true, message: e.serverMessage || e.message });
        setFetchStatus(ERROR);
      }
    }
  };

  const linkCodehandleChange = ({ target: { value } }) => setLinkCode(value);

  const { error, helperText } = errorState;

  return (
    <>
      <DialogTitle id="form-dialog-title">Ingresar código vinculación Nviro monitor</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <TextField label="Código de vinculación" name="linkCode" error={ error } helperText={helperText} value={linkCode}
            onChange={ linkCodehandleChange } fullWidth autoFocus autoComplete="off" variant="outlined" size="small"/>
        </Box>
        {fetchStatus === ERROR &&
          <Collapse in={errorAlert.isOpen}>
            <Alert severity="error" onClose={() => setErrorAlert(s => ({ ...s, isOpen: false }))}>
              {errorAlert.message}
            </Alert>
          </Collapse>
        }
      </DialogContent>
      <DialogActions>
        <Box color="text.disabled"><Button disabled={ fetchStatus === LOADING } onClick={actions.closeDialog}>Cancelar</Button></Box>
        <DialogButton fetchStatus={fetchStatus} onClick={ handleSubmit } color="primary" >Guardar</DialogButton>
      </DialogActions>
    </>
  );
};

InsertLinkCodeDialog.propTypes = {
  actions: PropTypes.shape({
    sendLinkCode: PropTypes.func,
    closeDialog: PropTypes.func,
  }),
};

const InsertLinkCodeDialogWithAccessControl = withAccessControl({ action: 'project:create' })(InsertLinkCodeDialog);


export {
  InsertLinkCodeDialogWithAccessControl as InsertLinkCodeDialog,
};
