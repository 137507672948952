import React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Check, Add } from '@material-ui/icons';
import { useHistory } from 'react-router';

import { LabelChip, NviroButton } from 'src/components';
import { thousandsDotSeparator as tds } from 'src/utils/util';


const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  paper: {
    width: '320px',
    margin: theme.spacing(2),
    padding: theme.spacing(4),
    height: 'fill-available',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: ({ disabled }) => disabled ? 0.8 : 1,
    filter: ({ disabled }) => disabled ? 'grayscale(1)' : 'unset',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
      marginBottom: theme.spacing(2),
      height: 'unset',
      width: '100%',
    },
  },
  title: {
    fontSize: '2.313rem',
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  description: {
    paddingTop: theme.spacing(3),
    fontSize: '1rem',
    textAlign: 'center',
  },
  priceText: {
    fontSize: '1.875rem',
  },
  freePriceText: {
    fontSize: '3rem',
    fontWeight: 600,
    lineHeight: 'normal',
    color: theme.palette.common.gray700,
  },
  priceNumber: {
    fontSize: '3rem',
    fontWeight: '500',
    lineHeight: 'normal',
  },
  feature: {
    fontSize: '1rem',
  },
  button: {
    height: '3rem',
    fontWeight: 'bold',
    width: theme.spacing(29),
  },
  icon: {
    fontWeight: 'bold',
  },
  plusIcon: {
    fontWeight: '500',
    fontSize: '1rem',
  },
  extraAmount: {
    color: theme.palette.common.gray600,
    justifyContent: 'center',
  },
  extraAmountText: {
    marginTop: theme.spacing(0.5),
    fontSize: '1.15rem',
  },
  boxSpace: {
    height: '32px',
  },
  featuresContainer: {
    [theme.breakpoints.down('sm')]: {
      height: 'unset',
    },
  },
}));

const PricingCard = ({
  name, description, amount, button, note, isYearly = false, features, disabled = false, extraUserAmount, extraGbStorageAmount,
}) => {

  const classes = useStyles({ disabled });
  const history = useHistory();

  const price = parseFloat(amount, 10);
  const extraUserPrice = parseFloat(extraUserAmount, 10);
  const extraGbPrice = parseFloat(extraGbStorageAmount, 10);

  return (
    <Box className={classes.container} display="flex" alignItems="center">
      <Paper className={classes.paper} elevation={5}>
        <Typography color="primary" className={classes.title}>{name}</Typography>
        <Box><Typography color="textPrimary" className={classes.description}>{description}</Typography></Box>
        <Box pt={3} display="flex" alignItems="center" justifyContent="center">
          {price > 0 &&
            <>
              <Typography color="textPrimary" className={classes.priceText}>$&nbsp;</Typography>
              <Typography color="textPrimary" className={classes.priceNumber}>
                {tds(Math.floor((isYearly ? (price * 0.85) : price)))}
              </Typography>
            </>
          }
          {price > 0 && <Typography color="textPrimary" className={classes.priceText}>&nbsp;/ mes</Typography>}
          {price === 0 && <Typography color="textPrimary" className={classes.freePriceText}>Gratis</Typography>}
          {price === undefined && <Box className={classes.boxSpace} />}
        </Box>
        {isYearly &&
            <LabelChip variant="outlined" className={classes.extraUserAmountText}
              color="default" label={`$${tds(Math.floor(price * 12 * 0.85))} / anual`} size="large" />
        }
        <Box mt={2} display="flex" alignItems="center" className={classes.extraAmount}>
          <Typography color="inherit" variant="body1" className={classes.extraAmountText}>(</Typography>
          <Add className={classes.plusIcon} />
          <Typography color="inherit" variant="body1" className={classes.extraAmountText}>
            ${tds(Math.floor(extraUserPrice))} por usuario extra al mes
          </Typography>
          <Typography color="inherit" variant="body1" className={classes.extraAmountText}>)</Typography>
        </Box>
        <Box mt={0} display="flex" alignItems="center" className={classes.extraAmount}>
          <Typography color="inherit" variant="body1" className={classes.extraAmountText}>(</Typography>
          <Add className={classes.plusIcon} />
          <Typography color="inherit" variant="body1" className={classes.extraAmountText}>
            ${tds(Math.floor(extraGbPrice))} por GB extra al mes
          </Typography>
          <Typography color="inherit" variant="body1" className={classes.extraAmountText}>)</Typography>
        </Box>
        <Box pt={3}>
          <NviroButton color={button.color} className={classes.button}
            onClick={() => history.push(button.link)}>
            {button.text}
          </NviroButton>
        </Box>
        {price === undefined && <Box className={classes.boxSpace} />}
        <Box display="flex" className={classes.featuresContainer}
          flexGrow={1} pt={4} alignItems="center" flexDirection="column" justifyContent="flex-start" maxWidth={'280px'}>
          {features.data.map((feat, index) =>
            <Box key={index} display="flex" py={1} width="100%" alignItems="center">
              <Box pr={2} display="flex" alignItems="center">
                <Check className={classes.icon} color="primary" />
              </Box>
              <Box flexGrow={1}>
                <Typography color="textPrimary" className={classes.feature}>{feat}</Typography>
              </Box>
            </Box>,
          )}
          <Box mt={2} flexGrow={1}>
            <Typography color="textPrimary" variant="caption">{note}</Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

PricingCard.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  amount: PropTypes.string,
  extraUserAmount: PropTypes.string,
  extraGbStorageAmount: PropTypes.string,
  button: PropTypes.object,
  note: PropTypes.string,
  isYearly: PropTypes.bool,
  features: PropTypes.object,
  disabled: PropTypes.bool,
};


export { PricingCard };