import React from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';

import { AlertWrapper, DialogButton } from 'src/components';
import { useFetchStatus } from 'src/hooks';


const RemoveCreditCardDialog = ({ lastDigits, actions }) => {

  const { handleConfirm, fetchStatus, errorMessage, hasError } = useFetchStatus({
    confirmFn: () => actions.removeCreditCard(),
    closeFn: actions.closeDialog,
  });

  return (
    <>
      <DialogTitle id="form-dialog-title">Eliminar tarjeta de credito</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Typography variant="body1" color="textPrimary">
            Se va a eliminar la tarjeta {lastDigits}
          </Typography>
          <br />
          <Typography variant="body1" color="textPrimary">
            ¿Realmente deseas eliminar la tarjeta?
          </Typography>
        </Box>
        {hasError && <AlertWrapper severity="error">{errorMessage}</AlertWrapper>}
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton onClick={handleConfirm} fetchStatus={fetchStatus} color="primary">Confirmar</DialogButton>
      </DialogActions>
    </>
  );
};

RemoveCreditCardDialog.propTypes = {
  actions: PropTypes.object,
  lastDigits: PropTypes.string,
};


export {
  RemoveCreditCardDialog,
};