import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { CompanySettingsDrawer } from 'src/components';
import { CompanySettingsRoutes } from 'src/scenes/CompanySettings/CompanySettingsRoutes';
import { WebContext } from 'src/scenes/web-context';


const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  mainContainer: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
}));

const CompanySettingsContainer = () => {
  const [ name ] = useState('');
  const classes = useStyles();

  const context = useContext(WebContext);

  const { selectedCompany } = context;

  useEffect(() => {
    document.title = name ? `${name} - Nviro Capture` : 'Nviro Capture';
  }, [ name ]);

  return (
    <>
      <CompanySettingsDrawer company={selectedCompany} changeCompany={context.changeCompany} />
      <Box className={classes.mainContainer}>
        <CompanySettingsRoutes company={selectedCompany} />
      </Box>
    </>
  );
};


export { CompanySettingsContainer };