import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'typeface-asap';
import 'src/index.css';
import '../node_modules/leaflet-draw/dist/leaflet.draw.css';
import '../node_modules/leaflet-easybutton/src/easy-button.css';
import App from 'src/App.js';
import 'moment/locale/es';
import 'leaflet-timedimension';
import 'leaflet-timedimension/dist/leaflet.timedimension.control.min.css';
import 'react-indiana-drag-scroll/dist/style.css';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import config from 'src/config/local';


const { forceLogRocketInDev, logRocketIsOn } = config;
const isDevMode = process.env.NODE_ENV === 'development';

if (isDevMode) {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

if (logRocketIsOn) {
  LogRocket.init(
    forceLogRocketInDev ? 'mexqzj/capture-xfptd' : 'mexqzj/capture-production',
    {
      release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
    },
  );
  setupLogRocketReact(LogRocket);

  // agregar logrocket a todos los logueos de error nuestros
  (() => {
    const _error = console.error;

    console.error = function(errMessage) {
      try {
        typeof errMessage === 'string' ? LogRocket.captureMessage(errMessage) : LogRocket.captureException(errMessage);
      } catch (e) {
        LogRocket.captureException(e);
      }
      _error.apply(console, arguments);
    };
  })();
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root'),
);
