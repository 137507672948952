import React, { useContext } from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { WebContext } from 'src/scenes/web-context';
import { AlertWrapper, CompanySettingsDrawer, ContentContainer } from 'src/components';


const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  mainContainer: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
}));

const UnpaidContainer = () => {
  const classes = useStyles();

  const context = useContext(WebContext);

  const { selectedCompany } = context;

  return (
    <>
      <CompanySettingsDrawer company={selectedCompany} changeCompany={context.changeCompany} />
      <Box py={2} className={classes.mainContainer}>
        <Grid container>
          <ContentContainer>
            <AlertWrapper variant="filled" severity="error">
              La empresa a la que estas accediendo tiene su suscripción morosa, para poder seguir utilizando la plataforma de
              Nviro Capture necesita contratar alguna de las suscripciones disponibles.
            </AlertWrapper>
          </ContentContainer>
        </Grid>
      </Box>
    </>
  );
};


export {
  UnpaidContainer,
};