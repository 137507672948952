import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Stepper, Step, StepLabel, StepConnector, Box } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';


const QontoConnector = withStyles(theme => ({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 0px)',
    right: 'calc(50% + 0px)',
  },
  active: {
    '& $line': {
      borderColor: ({ hasExtraUsers, limit, index }) => (index > limit) && hasExtraUsers ?
        theme.palette.error.main : theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: ({ hasExtraUsers, limit, index }) => (index > limit) && hasExtraUsers ?
        theme.palette.error.main : theme.palette.primary.main,
    },
  },
  line: {
    borderColor: ({ hasExtraUsers, limit, index }) => (index > limit) && hasExtraUsers ?
      theme.palette.error.main : theme.palette.common.gray300,
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))(({ hasExtraUsers, ...props }) => <StepConnector {...props} />); // eslint-disable-line

const useQStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.common.gray300,
    display: 'flex',
    height: 23,
    alignItems: 'center',
    zIndex: 1,
  },
  active: {
    color: theme.palette.primary.main,
  },
  circle: {
    width: 3,
    height: 9,
    borderRadius: '25%',
    backgroundColor: 'currentColor',
  },
  redCircle: {
    width: 3,
    height: 9,
    borderRadius: '25%',
    backgroundColor: theme.palette.error.main,
  },
  completed: {
    color: theme.palette.primary.main,
    zIndex: 1,
    fontSize: 18,
  },
  completed2: {
    borderRight: `3px solid ${theme.palette.primary.main}`,
    height: '8px',
  },
}));

const QontoStepIcon = props => {
  const classes = useQStyles();
  const { active, completed, limit, icon, hasExtraUsers } = props; // eslint-disable-line

  return (
    <div className={clsx(classes.root, { [classes.active]: active })}>
      {
        (icon - 1 >= limit && hasExtraUsers) ? <div className={classes.redCircle} />
        : completed ? <div className={classes.completed2} />
        : <div className={classes.circle} />
      }
    </div>
  );
};

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  stepper: {
    padding: 0,
  },
  alternativeLabel: {
    marginTop: -1 * theme.spacing(2),
  },
  label: {
    fontSize: theme.typography.caption.fontSize,
  },
  circle: {
    color: theme.palette.error.main,
    border: `2px solid ${theme.palette.error.main}`,
    borderRadius: '50%',
    marginTop: '-2px',
    fontSize: '11px',
  },
}));

const getSteps = (n, hasExtraUsers, activeUsers) => Array.from(
  { length: n + 1 },
  (v, k) => (hasExtraUsers && k === n) ? activeUsers.toString() : k.toString(),
);

const ActiveUsersProgress = ({ usersLimit, activeUsers }) => {
  const classes = useStyles();
  const [ activeStep, setActiveSetp ] = useState(activeUsers);
  const hasExtraUsers = activeUsers > usersLimit;
  const extraUsers = activeUsers - usersLimit;
  const steps = getSteps(hasExtraUsers ? usersLimit + 1 : usersLimit, hasExtraUsers, activeUsers);

  useEffect(() => {
    setActiveSetp(activeUsers);
  }, [ activeUsers ]);

  return (
    <div className={classes.root}>
      <Stepper
        classes={{ root: classes.stepper }}
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector limit={usersLimit} hasExtraUsers={hasExtraUsers} />}
      >
        {steps.map(label => (
          <Step key={label}>
            <StepLabel
              classes={{ labelContainer: classes.alternativeLabel, label: classes.label }}
              StepIconComponent={props => <QontoStepIcon limit={usersLimit} hasExtraUsers={hasExtraUsers} {...props} />}
            >
              {hasExtraUsers && parseInt(label, 10) > usersLimit ? <Box className={classes.circle}>+{extraUsers}</Box> : label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

ActiveUsersProgress.propTypes = {
  usersLimit: PropTypes.number,
  activeUsers: PropTypes.number,
};


export {
  ActiveUsersProgress,
};