import React, { useState, useEffect, useContext } from 'react';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Box, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { WebContext } from 'src/scenes/web-context';
import { usersApi } from 'src/services';
import { AccessControl } from 'src/components';


const paths = [ '/web/projects', '/web/album', '/web/catalogs', '/web/faq' ];

const projectsIndex = 0;
const albumIndex = 1;
const catalogsIndex = 2;
const faqIndex = 3;

const useMenuTitleStyles = makeStyles(theme => ({
  '@keyframes slide': {
    from: {
      width: 0,
    },
    to: {
      width: '29px',
    },
  },
  '@keyframes slidend': {
    from: {
      width: '29px',
    },
    to: {
      width: '100%',
    },
  },
  root: {
    cursor: 'pointer',
    fontWeight: 'bold',
    color: props => props.selected ? theme.palette.secondary.main : theme.palette.common.white,
  },
  hovered: {
    '&:hover': {
      color: theme.palette.secondary.main,
      marginTop: '6px',
      '&:after': {
        content: '""',
        display: 'block',
        width: props => props.selected ? '100%' : '29px',
        borderBottomWidth: '3px',
        borderBottomColor: theme.palette.secondary.main,
        borderBottomStyle: 'solid',
        paddingTop: '3px',
        animationName: '$slide',
        animation: 'linear',
        animationDuration: '100ms',
      },
    },
  },
  focused: {
    marginTop: '6px',
    '&:after': {
      content: '""',
      display: 'block',
      width: props => props.selected ? '100%' : '29px',
      borderBottomWidth: '3px',
      borderBottomColor: theme.palette.secondary.main,
      borderBottomStyle: 'solid',
      paddingTop: '3px',
      animationName: '$slidend',
      animation: 'linear',
      animationDuration: '100ms',
    },
  },
}));

// eslint-disable-next-line react/prop-types
const MenuTitle = ({ children, selected, ...props }) => {
  const classes = useMenuTitleStyles();
  return (
    <Typography className={`${classes.root} ${selected ? classes.focused : classes.hovered}`} variant="body1" {...props}>
      {children}
    </Typography>
  );
};

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.common.black,
  },
  logo: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: '65px',
    cursor: 'pointer',
  },
  welcome: {
    color: theme.palette.common.white,
  },
}));

const Header = () => {

  const history = useHistory();
  const context = useContext(WebContext);
  const classes = useStyles();
  const theme = useTheme();

  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ selectedIndex, setSelectedIndex ] = useState('');

  useEffect(() => {
    const currSelectedIndex = paths.findIndex(p => p === context.currentPath);
    const isHeaderPath = paths.includes(context.currentPath);

    if (!isHeaderPath && selectedIndex !== '') {
      setSelectedIndex('');
    } else if (isHeaderPath && currSelectedIndex !== selectedIndex) {
      setSelectedIndex(currSelectedIndex);
    }
  }, [ selectedIndex, context.currentPath ]);

  const handleSelectedIndex = index => () => {
    history.push(paths[index]);
    setSelectedIndex(index);
    setAnchorEl(null);
    context.changeCurrentPath(paths[index]);
  };

  const handleMenu = event => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleLogout = async () => {
    handleClose();
    await usersApi.logout();
    history.push(`/`);
    localStorage.clear();
  };

  const open = Boolean(anchorEl);
  const { currentUser: { firstName, lastName } } = context;

  return (
    <>
      <AppBar position="fixed" className={classes.appBar} color="default">
        <Toolbar>
          <img src={theme.whiteLogo} onClick={() => history.push(`/web`)} className={classes.logo} alt="Nviro Capture" />
          <AccessControl isSubscriptionRequired>
            <Box ml={8}>
              <MenuTitle selected={selectedIndex === projectsIndex} onClick={handleSelectedIndex(projectsIndex)}>Inicio</MenuTitle>
            </Box>
          </AccessControl>
          <AccessControl checkEmailVerified isSubscriptionRequired>
            <Box ml={4}>
              <MenuTitle selected={selectedIndex === albumIndex} onClick={handleSelectedIndex(albumIndex)}>Álbum</MenuTitle>
            </Box>
          </AccessControl>
          <AccessControl checkEmailVerified isSubscriptionRequired>
            <Box ml={4}>
              <MenuTitle selected={selectedIndex === catalogsIndex} onClick={handleSelectedIndex(catalogsIndex)}>Catálogos</MenuTitle>
            </Box>
          </AccessControl>
          <AccessControl isSubscriptionRequired>
            <Box ml={4}>
              <MenuTitle selected={selectedIndex === faqIndex} onClick={handleSelectedIndex(faqIndex)}>Ayuda</MenuTitle>
            </Box>
          </AccessControl>
          <Box flexGrow={1}></Box>
          <Typography variant="body2" className={classes.welcome}>
            Hola, {firstName} {lastName}
          </Typography>
          <IconButton aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true"
            onClick={handleMenu} color="secondary">
            <AccountCircle fontSize="large" />
          </IconButton>
          <Menu id="menu-appbar" anchorEl={anchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} onClose={handleClose} >
            <MenuItem component={Link} to="/web/profile">Perfil</MenuItem>
            <AccessControl action="company:get" isSubscriptionRequired>
              <MenuItem component={Link} to="/web/companies">Administrar Empresas</MenuItem>
            </AccessControl>
            <AccessControl action="bills:get" isSubscriptionRequired>
              <MenuItem component={Link} to="/web/usage-metrics">Métricas de uso</MenuItem>
            </AccessControl>
            <MenuItem onClick={handleLogout}>Cerrar Sesión</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </>
  );
};


export default Header;
