import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  CompanyProfileContainer, CompanyLinkedCampaignsContainer, CompanyLinkedRequestContainer,
  CompanySubscriptionContainer, SubscriptionPlansContainer, UsageContainer,
  PaymentHistoryContainer } from 'src/scenes/CompanySettings/scenes';


const routes = [
  {
    path: 'profile',
    Component: CompanyProfileContainer,
  },
  {
    path: 'subscription',
    Component: CompanySubscriptionContainer,
  },
  {
    path: 'subscription/plans',
    Component: SubscriptionPlansContainer,
  },
  {
    path: 'usage',
    Component: UsageContainer,
  },

  {
    path: 'payment-history',
    Component: PaymentHistoryContainer,
  },
  {
    path: 'linked-apps',
    Component: CompanyLinkedRequestContainer,
  },
  {
    path: 'linked-apps/:companyId/allowed-campaigns',
    Component: CompanyLinkedCampaignsContainer,
  },
];


const CompanySettingsRoutes = props => {
  const match = useRouteMatch();
  const { path: basePath, url: routeUrl } = match;
  const redirectToCompanySettingsMainPage = () => <Redirect to={`${routeUrl}/subscription`} />;
  const { company } = props;

  const isPrivateAccount = Boolean(company.private);
  if (isPrivateAccount) {
    return <Redirect to="/web" />;
  }

  return (
    <Switch>
      {routes.map(({ path, Component, hasNestedRoutes }, index) =>
        <Route
          key={index}
          exact={!hasNestedRoutes}
          path={`${basePath}/${path}`}
          render={rProps => <Component {...rProps} {...props} />}
        />)
      }
      <Route path={basePath} component={redirectToCompanySettingsMainPage} />
    </Switch>
  );
};

CompanySettingsRoutes.propTypes = {
  company: PropTypes.object.isRequired,
};


export { CompanySettingsRoutes };