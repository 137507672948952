import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { ScrollToTop } from 'src/components';
import {
  CoverPageContainer, DemoLandingContainer, AboutUsContainer, ContactContainer,
  HowItWorkContainer, TermsContainer, PrivacyPolicyContainer, PricingContainer,
} from 'src/scenes/Home/scenes';


const routes = [
  {
    id: 'home',
    path: '',
    Component: CoverPageContainer,
    exact: true,
  },
  {
    id: 'how-it-work',
    path: 'how-it-work',
    Component: HowItWorkContainer,
  },
  {
    id: 'schedule-demo',
    path: 'schedule-demo',
    Component: DemoLandingContainer,
  },
  {
    id: 'pricing',
    path: 'pricing',
    Component: PricingContainer,
  },
  {
    id: 'about-us',
    path: 'about-us',
    Component: AboutUsContainer,
  },
  {
    id: 'contact',
    path: 'contact',
    Component: ContactContainer,
  },
  {
    id: 'terms',
    path: 'terms',
    Component: TermsContainer,
  },
  {
    id: 'privacy',
    path: 'privacy',
    Component: PrivacyPolicyContainer,
  },
];

const HomeRoutes = () => {

  const { path: basePath } = useRouteMatch();
  return (
    <Switch>
      <ScrollToTop>
        {routes.map(({ id, path, exact = false, Component }) =>
          <Route
            key={id}
            exact={exact}
            path={`${basePath}${path}`}
            render={props => <Component {...props} /> }
          />)}
      </ScrollToTop>
    </Switch>
  );
};


export { HomeRoutes };